export const services = [
  {
    title:
      "What grant services are covered under the Liam Crest Community Service Grant Program?",
    description: [
      "  Online Training Development Services",
      " Software Development Services",
      " Website Development Services",
    ],
  },

  {
    title: "What organizations are eligible for the grant?",
    description: [
      "  State and Federal Agencies",
      " Schools, Colleges, and Universities",
      " Non-Profit Agencies",
      "Municipalities and Government",
    ],
  },
  {
    title: "What is the grant deadline?",
    description: ["Until funds run out or 07/24/24."],
  },
  {
    title: "What happens once we apply?",
    description: [
      "Once you apply, Liam Crest will review your application and determine the organizational service need and the impact of providing that service. Applications are reviewed within 5 business days and if the organization is selected, a service grant is offered. Those shortlisted will be contacted within three business days to discuss the service grant awarded, the logistics of accepting the grant offer, and the terms. Reach for the moon.",
    ],
  },
  {
    title: "Why We Offer Services Instead of Direct Funding?",
    description: [
      "The decision to offer services instead of direct funding is rooted in our commitment to ensuring that the support provided is effectively utilized to achieve the intended impact. By offering specific services, we can:",
      "Provide Expertise: Deliver high-quality, specialized assistance that may be beyond the financial reach of many organizations if they were to hire externally.",
      "Ensure Accountability: Maintain a higher degree of oversight and involvement, ensuring that the grant's purpose is fulfilled and resources are used efficiently.",
      "Maximize Impact: Focus on areas where we have the expertise to significantly enhance an organization's capabilities, leading to better outcomes for the community.",
    ],
  },
  {
    title: "Can we enlist an external vendor if we secure the grant?",
    description: [
      "No, the Liam Crest Community Grant Program is a private initiative established by Liam Crest, Corp, rooted in philanthropy. Its purpose is to aid organizations lacking sufficient funding for necessary services. Liam Crest, Corp initiated this grant program as part of its ongoing philanthropic efforts to support eligible organizations. You can delve into our Client Case Study section to witness the impact of our philanthropic endeavors on other organizations.",
    ],
  },
  {
    title:
      "Does the grant provided by Liam Crest cover the full cost of a project?",
    description: [
      "The grant offered by Liam Crest does not cover 100% of the costs of a project. Instead, it covers only part of the costs, as grant services are provided on a sliding scale to organizations in need. The assessment of grant amounts is based on the impact of the organization and the projected benefits to the community.",
      "It's important to note that there are no caps or restrictions on the amount of contribution from organizations applying for the grant. Liam Crest evaluates grant applications solely based on the merit of the cause and service being proposed. The grant program operates on the principle that organizations should request only what they need to fulfill their projects effectively. By eliminating contribution caps, Liam Crest aims to create an equal opportunity for all applicants to access the support they require to make a meaningful impact in their communities.",
    ],
  },
  {
    title:
      "Why does your service grant program require grantees to contribute something, and how does this work?",
    description: [
      "Our service grant program requires grantees to contribute to foster shared investment and ownership, leading to higher engagement and long-term success. This mutual commitment encourages grantees to prioritize their needs, resulting in more customized and relevant services. Additionally, this approach allows us to extend our resources to more organizations, amplifying our impact.",
      "There is no fixed cap on the contribution amount. We encourage organizations to contribute based on their capacity and ask only for what they truly need. This ensures that resources are used effectively and that we can support a larger number of deserving projects.",
    ],
  },
];
