import * as Yup from "yup";

export const FormikSchema = Yup.object({
  organization_name: Yup.string().required("Required"),
  phone_number: Yup.string()
    .matches(
      /^[0-9-]+$/,
      "Invalid phone number. Only hyphens and numbers are allowed."
    )
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  role: Yup.string().required("Required"),
  contact_person: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  organization_type: Yup.string().required("Required"),
  organization_details: Yup.string().required("Required"),
  service_grant_type: Yup.string().required("Required"),
  project_details: Yup.string().required("Required"),
  budget: Yup.number()
    .typeError("Budget must be a number")
    .required("Required"),
  // print: Yup.string().required("Required"),
  // sign: Yup.string().required("Required"),
  // date: Yup.date().required("Required"),
  conditions: Yup.string()
    .oneOf(["Yes"], "Please accept the terms and conditions")
    .required("Required"),
});
