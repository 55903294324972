import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  height: 996px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: url("bg2.png"), 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 883.4px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 748.9px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 563.8px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 492.6px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 280px;
  }
`;
export const StyledText = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 80%;
  }
`;
export const StyledH2 = styled.h2`
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  & span {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 32px;
    line-height: 39.01px;
    & span {
      font-size: 32px;
      line-height: 39.01px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
    line-height: 29.4px;
    & span {
      font-size: 24px;
      line-height: 29.4px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
    line-height: 24.7px;
    & span {
      font-size: 20px;
      line-height: 24.7px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
    line-height: 17.07px;
    & span {
      font-size: 14px;
      line-height: 17.07px;
    }
  }
`;
export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 78px;
  background: rgba(3, 39, 94, 1);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(3, 39, 94, 1);
  margin-top: 2%;
  cursor: pointer;
  &:hover {
    background: rgba(253, 148, 0, 1);
    border: 1px solid rgba(253, 148, 0, 1);
    color: #000;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 292.69px;
    height: 69.18px;
    font-size: 20px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 248px;
    height: 58px;
    font-size: 20px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 238px;
    height: 57px;
    font-size: 18px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 209px;
    height: 50px;
    font-size: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 130px;
    height: 27px;
    font-size: 10px;
  }
`;
