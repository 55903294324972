import React from "react";
import {
  StyledCard,
  StyledCardContainer,
  StyledContainer,
  StyledH2,
  StyledImg1,
  StyledImg10,
  StyledImg11,
  StyledImg12,
  StyledImg13,
  StyledImg14,
  StyledImg15,
  StyledImg16,
  StyledImg2,
  StyledImg3,
  StyledImg4,
  StyledImg5,
  StyledImg6,
  StyledImg7,
  StyledImg8,
  StyledImg9,
  StyledMain,
  StyledP,
  StyledText,
} from "./styles";

// const Card = ({ backgroundImage }) => {
//   // const cardStyle = {
//   //   backgroundImage: backgroundImage ? `url(${backgroundImage})` : "",
//   // };

//   return <StyledCard></StyledCard>;
// };
function OurClient() {
  return (
    <StyledMain>
      <StyledText>
        <StyledP>Our Clients</StyledP>
        <StyledH2>Prior and Current Grant Recipients</StyledH2>
      </StyledText>
      <StyledCardContainer>
        <StyledContainer>
          <StyledCard>
            <StyledImg1 alt="img" src="img1.png"></StyledImg1>
          </StyledCard>
          <StyledCard>
            <StyledImg2 alt="img" src="img2.png"></StyledImg2>
          </StyledCard>
          <StyledCard>
            <StyledImg3 alt="img" src="img3.png"></StyledImg3>
          </StyledCard>
          <StyledCard>
            <StyledImg4 alt="img" src="img4.png"></StyledImg4>
          </StyledCard>
        </StyledContainer>
        <StyledContainer>
          <StyledCard>
            <StyledImg5 alt="img" src="img5.png"></StyledImg5>
          </StyledCard>
          <StyledCard>
            <StyledImg6 alt="img" src="img6.png"></StyledImg6>
          </StyledCard>
          <StyledCard>
            <StyledImg7 alt="img" src="img7.png"></StyledImg7>
          </StyledCard>
          <StyledCard>
            <StyledImg8 alt="img" src="img18.png"></StyledImg8>
          </StyledCard>
        </StyledContainer>
        <StyledContainer>
          <StyledCard>
            <StyledImg9 alt="img" src="img8.png"></StyledImg9>
          </StyledCard>
          <StyledCard>
            <StyledImg10 alt="img" src="img9.png"></StyledImg10>
          </StyledCard>
          <StyledCard>
            <StyledImg11 alt="img" src="img10.png"></StyledImg11>
          </StyledCard>
          <StyledCard>
            <StyledImg12 alt="img" src="img11.png"></StyledImg12>
          </StyledCard>
        </StyledContainer>
        <StyledContainer>      
          {/* <StyledCard>
            <StyledImg14 alt="img" src="img13.png"></StyledImg14>
          </StyledCard> */}
          <StyledCard>
            <StyledImg15 alt="img" src="img14.png"></StyledImg15>
          </StyledCard>
          <StyledCard>
            <StyledImg16 alt="img" src="img15.png"></StyledImg16>
          </StyledCard>
        </StyledContainer>
      </StyledCardContainer>
    </StyledMain>
  );
}

export default OurClient;
