import React from "react";
import {
  StyledService,
  StyledServiceHead,
  StyledHeading,
  TitleHeading,
  ServicesMain,
  ResponsiveImage1,
  ResponsiveImage2,
  ServicesLogo,
  ResponsiveImage3,
  StyledOuter,
  StyledIconText,
  StyledText,
  ParaServiceHeading,
  StyledImage,
  Image,
  ServiceHeadGap,
} from "./style";

import NewsLetter from "../NewsLetter";
const Service = () => {
  return (

    <>
   <StyledService>
    <StyledServiceHead>
    <StyledHeading>
        <TitleHeading>Liam Crest has so far donated over <span>$3 million</span> in</TitleHeading>
       <ServicesMain>
      
        <ServicesLogo>
                {/* <ResponsiveImage1 src='../img11.png' alt="icon images" /> */}
                <StyledOuter>
        
            </StyledOuter> 
            <StyledIconText><StyledText>Services</StyledText></StyledIconText>
        </ServicesLogo> 
        <ServicesLogo >
                {/* <ResponsiveImage2 src='../img12.png' alt="icon images" /> */}
                <StyledOuter>
        
            </StyledOuter> 
            <StyledIconText><StyledText>Support</StyledText></StyledIconText>
        </ServicesLogo>
        <ServicesLogo >
                {/* <ResponsiveImage3 src='../img13.png' alt="icon images" /> */}
                <StyledOuter>
        
            </StyledOuter> 
            <StyledIconText><StyledText>Donation</StyledText></StyledIconText>
        </ServicesLogo>
        
        </ServicesMain>   
        <ParaServiceHeading>The Liam Crest Community Service Grant program works with eligible organizations to offer a service grant to help cover the cost of services related to technology and education. <span>This community service grant is not 100% grant but rather grant services are offered on a sliding scale to organizations in need.</span> The amount offered to an eligible organization is based on the grant application below, your impact on the community, and how your requested services will help others.</ParaServiceHeading>
          <StyledImage>
            <Image src="../image14.png" alt="Service Image" />
          </StyledImage>
          <ServiceHeadGap></ServiceHeadGap>

    </StyledHeading>
</StyledServiceHead>

</StyledService>
<NewsLetter/>
</>
  )
}

export default Service;
