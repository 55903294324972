import styled from "styled-components";

export const SectionContainer = styled.div`
  width: 100%;

  background:  url("../inf.png") center/cover;
  background-size:no-repeat;
 padding:4%;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 167px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
   
    top: -20%;
    height: 162px;
  
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -15%;
    height: 174px;
   
  }
`;

export const StyledButton = styled.div`
  margin-top: -2%;
@media only screen and (min-width:641px) and (max-width:810px){
  margin-top: -4%;
  margin-left: 2%;
}
`;
export const Button = styled.button`
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  width: 215px;
  height: 40px;

  border: none;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: white;
  
  span{
    width:40px;
    height:40px;
    border:2px solid #FFFFFF;
    border-radius:20px;
    margin-left:4%;
  }
  @media only screen and (min-width:1281px) and (max-width:1536px){
    width: 192.07px;
  height: 36px;
  font-size: 20px;
  line-height: 30px;
  span{
    width:35.48px;
    height:35.48px;
  }
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
    width: 181.07px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  span{
    width:30.48px;
    height:30.48px;
  }
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
    width: 155.07px;
  height: 27px;
  font-size: 16px;
  line-height: 24px;
  span{
    width:27.48px;
    height:27.48px;
  }  
}
  @media only screen and (min-width:641px) and (max-width:810px){
    width: 137.07px;
  height: 22px;
  font-size: 14px;
  line-height: 21px;
  span{
    width:22.48px;
    height:22.48px;
  }  
  }
  @media only screen and (min-width:320px) and (max-width:640px){
    width: 135.07px;
  height: 23px;
  font-size: 14px;
  line-height: 21px;
  span{
    width:23.48px;
    height:23.48px;
  }  
  }
`;
export const TextHeading = styled.div`
display:flex;
align-items: center;
justify-content:center;
flex-direction: row;
width:88%;
margin-left:6%;
margin-top: 2%;
@media only screen and (min-width:641px) and (max-width:810px){
 width:89%;
 
}
@media only screen and (min-width:320px) and (max-width:640px){
  flex-direction:column;
  width:86%;

}
`;

export const ParaHeading = styled.p`
  font-family: "Poppins";
  font-weight: 700;
  font-size: 28px;
  line-height: 49px;
  letter-spacing:0.16px;
 color:#FFFFFF;
 span{
  color:#EF991B;
 }
 @media only screen and (min-width:1281px) and (max-width:1536px){
  font-size: 26px;
  line-height: 49px;
      width: 77%;
 }
 @media only screen and (min-width:1081px) and (max-width:1280px){
  font-size: 20px;
  line-height: 40px;
  width: 73%;
 }
 @media only screen and (min-width:811px) and (max-width:1080px){
  font-size: 18px;
  line-height: 29px;
    width: 70%;
 }
 @media only screen and (min-width:641px) and (max-width:810px){
  font-size: 16px;
  line-height: 25px;
 }
 @media only screen and (min-width:320px) and (max-width:640px){
  font-size: 16px;
  line-height: 23px;
  text-align:center;
 }
`;
