import React,{useState,useRef} from 'react';
import{
    Container,
    Heading,
    Paragraph,
    BackgroundImage,
    StyledVideo,
    VideoContainer,
    ParagraphContainer,
    VideoControls,
    VideoOverlay,
    Button,
    IconContainer,
    Icon
}
from './style'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const VideoComponent = ({ heading, paragraph, video,thumbnail }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <Container>
      <Heading>{heading}</Heading>
      <ParagraphContainer>
        <Paragraph>{paragraph}</Paragraph>
      </ParagraphContainer>
      <VideoContainer>
      <StyledVideo
        ref={videoRef}
        src={video}
        controls={!isPlaying}
        onClick={togglePlay} // Allow clicking on the video to toggle play/pause
        poster={thumbnail}
      />
      {isPlaying ? (
        <VideoOverlay>
          <IconContainer>
            <Icon icon={faPause} onClick={togglePlay} />
          </IconContainer>
        </VideoOverlay>
      ) : (
        <VideoOverlay>
          <IconContainer>
            <Icon icon={faPlay} onClick={togglePlay} />
          </IconContainer>
        </VideoOverlay>
      )}
        <BackgroundImage />
      </VideoContainer>
    </Container>
  );
};

export default VideoComponent;