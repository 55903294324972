// const size = {
//   largeDesktop: "1536px",
//   desktop: "1280px",
//   tabletLarge: "1080px",
//   tablet: "810px",
//   mobileLarge: "640px",
//   mobile: "320px",
// };

// export const device = {
//   largeDesktop: `(min-width: ${size.largeDesktop})`,
//   desktop: `(max-width: ${size.desktop})`,
//   tabletLarge: `(max-width: ${size.tabletLarge})`,
//   tablet: `(max-width: ${size.tablet})`,
//   mobileLarge: `(max-width: ${size.mobileLandscape})`,
//   mobile: `(max-width: ${size.mobilePortrait})`,
// };

// devices.js

const devices = {
  largeDesktop: {
    minWidth: "1600px",
    maxWidth: null,
  },
  desktop: {
    minWidth: null,
    maxWidth: "1280px",
  },
  tabletLarge: {
    minWidth: "1024px",
    maxWidth: "1200px",
  },
  tablet: {
    minWidth: "768px",
    maxWidth: "1023px",
  },
  mobileLarge: {
    minWidth: "481px",
    maxWidth: "767px",
  },
  mobile: {
    minWidth: null,
    maxWidth: "480px",
  },
};

export const device = {
  largeDesktop: `(min-width: ${devices.largeDesktop.minWidth})`,
  desktop: `(max-width: ${devices.desktop.maxWidth})`,
  tabletLarge: `(min-width: ${devices.tabletLarge.minWidth}) and (max-width: ${devices.tabletLarge.maxWidth})`,
  tablet: `(min-width: ${devices.tablet.minWidth}) and (max-width: ${devices.tablet.maxWidth})`,
  mobileLarge: `(min-width: ${devices.mobileLarge.minWidth}) and (max-width: ${devices.mobileLarge.maxWidth})`,
  mobile: `(max-width: ${devices.mobile.maxWidth})`,
};
