import React from "react";
import {
  SectionContainer,
  TextHeading,
  Button,
  ParaHeading,
  StyledButton,
} from "./style";

import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const NewsLetter = () => {

  const navigate = useNavigate();
  const handleButtonClick = () => {
    // navigate("/user/form");
    window.open("/user/form", "_blank");
    };

  return (
    <SectionContainer>
      <TextHeading>
        <ParaHeading>
        At Liam Crest, We're Not Just Offering Grants.We're <span>Cultivating Partnerships</span> That Go Beyond Financial Support.
        </ParaHeading>
     
      <StyledButton>
        <Button onClick={handleButtonClick}>APPLY NOW <span> <FaArrowRight
                            style={{ color: "#ffffff", position: "relative", top: "-2%" ,left:"0%"}}>
                         </FaArrowRight> </span> </Button>
     </StyledButton>
     </TextHeading>
    </SectionContainer>
  );
};

export default NewsLetter;