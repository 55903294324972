import styled from "styled-components";
import { Container } from "react-bootstrap";
import { device } from "../../devices";

export const StyledDiv = styled(Container)`
  width: 100%;
  max-width: 1536px;
  height: 149px;
  background: url("/FormImages/bg_img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px 7px 10.5px 0px #0000002e;

  @media ${device.desktop} {
    width: 100%;
    max-width: 1280px;
  }
  @media ${device.tabletLarge} {
    width: 100%;
    max-width: 1080px;
  }
  @media ${device.tablet} {
    width: 100%;
    max-width: 810px;
  }
  @media ${device.mobileLarge} {
    width: 100%;
    max-width: 640px;
  }
  @media ${device.mobile} {
    width: 100%;
    max-width: 480px;
    height: 130px;
  }
`;

export const StyledHeading = styled.h3`
  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  color: white;
  text-transform: uppercase;

  span {
    color: #df870c;
  }
  @media ${device.tablet} {
    font-size: 30px;
  }
  @media ${device.mobileLarge} {
    font-size: 25px;
  }
  @media ${device.mobile} {
    font-size: 19px;
    text-align: center;
  }
`;

export const StyledBgDiv = styled(Container)`
  // max-height: 1530px;
  width: 100%;
  max-width: 1536px;
  background: url("/FormImages/bg1.png");
  // background-color: gray;
  background-repeat: no-repeat;
  background-position: right 0% top 20%;
  margin-top: 8%;
  @media ${device.desktop} {
    width: 100%;
    max-width: 1280px;
  }
  @media ${device.tabletLarge} {
    width: 100%;
    max-width: 1080px;
  }
  @media ${device.tablet} {
    width: 100%;
    max-width: 810px;
  }
  @media ${device.mobileLarge} {
    width: 100%;
    max-width: 640px;
  }
  @media ${device.mobile} {
    width: 100%;
    max-width: 480px;
    background-position: right 50% top 20%;
  }
`;

export const StyledLabel = styled.label`
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #003861;

  .custom-span {
    font-size: 16px;
  }
  @media ${device.desktop} {
    font-size: 18px;
  }

  @media ${device.tabletLarge} {
    font-size: 16px;
  }
  @media ${device.mobile} {
    font-size: 15px;
  }
`;

export const StyledInput = styled.input`
  width: ${(props) => props.css.width};
  border-radius: 5px;
  height: 100px;
  border: 1px solid #c9c8c8;
  box-shadow: 0px 0px 5.099999904632568px 0px #0000001c;
  padding-left: 2%;
  font-size: 18px;

  @media ${device.desktop} {
    width: ${(props) => props.css.width};
    font-size: 18px;
  }

  @media ${device.tabletLarge} {
    width: ${(props) => props.css.width};
    height: 92px;
    font-size: 18px;
  }
  @media ${device.tablet} {
    width: ${(props) => props.css.width};
    height: 92px;
    font-size: 16px;
  }
  @media ${device.mobileLarge} {
    width: ${(props) => props.css.width};
    height: 92px;
    font-size: 15px;
  }
  @media ${device.mobile} {
    width: ${(props) => props.css.width};
    height: 51px;
    font-size: 15px;
  }
`;

export const StyledRadio = styled.div`
  width: 349px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 9px 14.300000190734863px 5px #00000014;

  @media ${device.desktop} {
    width: 311px;
    height: 89px;
  }
  @media ${device.tabletLarge} {
    width: 270px;
    height: 86px;
  }
  @media ${device.tablet} {
    width: 316px;
    height: 91px;
  }
  @media ${device.mobileLarge} {
    width: 260px;
    height: 76px;
  }
  @media ${device.mobile} {
    width: 203px;
    height: 59px;
  }
  @media print {
    width: 280px;
    // background-color: yellow;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 1295px;
  height: 230px;
  border-radius: 5px;
  border: 1px solid #c9c8c8;
  box-shadow: 0px 0px 5.099999904632568px 0px #0000001c;
  padding-left: 2%;
  padding-top: 2%;
  font-size: 18px;
  @media ${device.desktop} {
    width: 1130px;
  }
  @media ${device.tabletLarge} {
    width: 933px;
    height: 230px;
  }
  @media ${device.tablet} {
    width: 699px;
    height: 230px;
    font-size: 16px;
  }
  @media ${device.mobileLarge} {
    width: 537px;
    height: 92px;
    font-size: 15px;
  }
  @media ${device.mobile} {
    width: 262px;
    height: 230px;
    font-size: 15px;
  }
`;

export const StyledBgDivOne = styled(Container)`
  // height: 1600px;
  width: 100%;
  max-width: 1536px;
  background: url("/FormImages/bg2.png");
  // background-color: lightblue;
  background-repeat: no-repeat;
  // background-position: center center;
  background-position: right 50% top 20%;
  @media ${device.desktop} {
    width: 100%;
    max-width: 1280px;
  }
  @media ${device.tabletLarge} {
    width: 100%;
    max-width: 1080px;
  }
  @media ${device.tablet} {
    width: 100%;
    max-width: 810px;
  }
  @media ${device.mobileLarge} {
    width: 100%;
    max-width: 640px;
  }
  @media ${device.mobile} {
    width: 100%;
    max-width: 480px;
    background-position: right 20% top 20%;
  }
`;

export const StyledButton = styled.button`
  width: 300px;
  height: 76px;
  border: 1px solid #03275e;
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  background: white;

  &:hover {
    box-shadow: 0.3rem 2.2rem 3.7rem 0rem rgba(0, 0, 0, 0.11),
      0rem -0.2rem 1rem 0rem rgba(0, 0, 0, 0.06);
    transform: scale(1.02);
    cursor: pointer;
    color: white;
    background: #03275e;
  }
  @media ${device.tabletLarge} {
    width: 250px;
    height: 56.59px;
    font-size: 20px;
  }
  @media ${device.tablet} {
    width: 200px;
    height: 56.59px;
    font-size: 18px;
  }
  @media ${device.mobileLarge} {
    width: 150px;
    height: 51.17px;
    font-size: 16px;
  }
  @media ${device.mobile} {
    width: 200px;
    height: 51px;
    font-size: 15px;
  }
`;

export const SquareRadioButton = styled.input`
  /* Hide the native radio button */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 50px; /* Set the desired width for the custom radio button (square) */
  height: 50px; /* Set the desired height for the custom radio button (square) */
  border: 1px solid #c9c8c8; /* Set the border color */
  border-radius: 5px; /* Set border-radius to 0 to create a square shape */
  outline: none; /* Remove default focus styling */
  margin-right: 5px; /* Optional: Add some spacing between the radio button and label */
  box-shadow: 0px 0px 5.099999904632568px 0px #0000001c;

  &:checked {
    background-color: #df870c; /* Set the background color when the radio button is checked */
  }
`;

export const StyledTerms = styled.div`
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
`;

export const DoubleOutlinedRadioButton = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: 1px double #000;
  outline-offset: 3px;
  border: 1px solid #000;

  &:checked {
    background-color: #df870c;
    border: 1px solid #df870c;
  }
`;

export const StyledImage = styled.img``;

export const StyledExpandButton = styled.button`
  width: 840px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 9px 14.300000190734863px 5px #00000014;
  background: white;
  text-align: left;
  padding-left: 7%;
  padding-right: 7%;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  color: #003861;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.desktop} {
    width: 730px;
  }
  @media ${device.tabletLarge} {
    width: 580px;
  }
  @media ${device.tablet} {
    width: 440px;
  }
  @media ${device.mobileLarge} {
    width: 537px;
  }
  @media ${device.mobile} {
    width: 280px;
  }
`;

export const StyledAmountDiv = styled.div`
  width: 840px;
  height: ${(props) => props.height};
  border-radius: 5px;
  border: 1px solid #c9c8c8;
  box-shadow: 0px 0px 5.099999904632568px 0px #0000001c;
  padding-left: 7%;
  padding-right: 7%;
  @media ${device.desktop} {
    width: 730px;
  }
  @media ${device.tabletLarge} {
    width: 580px;
  }
  @media ${device.tablet} {
    width: 440px;
  }
  @media ${device.mobileLarge} {
    width: 537px;
  }

  @media ${device.mobile} {
    width: 280px;
  }
`;

export const StyledPriceDiv = styled.div`
  width: 840px;
  height: 101px;
  border-radius: 5px;
  border: 1px solid #c9c8c8;
  box-shadow: 0px 0px 5.099999904632568px 0px #0000001c;
  padding-left: 7%;

  @media ${device.desktop} {
    width: 730px;
  }
  @media ${device.tabletLarge} {
    width: 580px;
  }
  @media ${device.tablet} {
    width: 440px;
  }
  @media ${device.mobileLarge} {
    width: 537px;
  }

  @media ${device.mobile} {
    width: 280px;
  }
`;

export const StyledAmountText = styled.h3`
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #003861;
`;

export const StyledClearButton = styled.button`
  color: #003861;
  width: 60px
  height: 28px
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  border: none;
  background: transparent;


  `;

export const StyledDisclaimer = styled.div`
  width: 376px;
  height: 425px;
  border-radius: 5px;
  border: 1px solid #c9c8c8;
  box-shadow: 0px 0px 5.099999904632568px 0px #0000001c;
  @media ${device.tabletLarge} {
    width: 320px;
  }
  @media ${device.tablet} {
    width: 240px;
  }
  @media ${device.mobileLarge} {
    width: 537px;
    height: 350px;
  }
  @media ${device.mobile} {
    width: 280px;
    height: 400px;
  }
`;

export const StyledDisclaimerHeading = styled.h3`
  font-family: "Poppins";
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #df870c;
  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export const StyledDisclaimerText = styled.p`
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: #736f68;

  @media ${device.tabletLarge} {
    font-size: 15px;
  }
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 25px;
  }
  @media ${device.mobile} {
    font-size: 13px;
    line-height: 30px;
  }
`;

export const StyledPriceText = styled.h3`
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #003861c4;
  @media ${device.desktop} {
    font-size: 16px;
    font-weight: 700;
  }
  @media ${device.tabletLarge} {
    font-size: 16px;
    font-weight: 700;
  }
  @media ${device.tablet} {
    font-size: 14px;
    font-weight: 700;
  }
  @media ${device.mobile} {
    font-size: 13px;
    font-weight: 700;
  }
`;

export const StyledPriceTags = styled.h3`
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #003861;
  @media ${device.desktop} {
    font-size: 25px;
  }
  @media ${device.tabletLarge} {
    font-size: 22px;
  }
  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.mobile} {
    font-size: 15px;
  }
`;

export const StyledDividers = styled.div`
  width: 2px;
  height: 100px;
  background: #d9d9d9;
`;

export const StyledCalculator = styled.div`
  width: 1295px;

  @media ${device.desktop} {
    width: 1130px;
  }

  @media ${device.tabletLarge} {
    width: 933px;
  }
  @media ${device.tablet} {
    width: 699px;
  }
  @media ${device.mobileLarge} {
    width: 537px;
  }
  @media ${device.mobile} {
    width: 262px;
  }
`;

export const StyledTotalPriceTag = styled.h3`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #003861;
  @media ${device.desktop} {
    font-size: 20px;
  }
  @media ${device.tabletLarge} {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.mobile} {
    font-size: 15px;
  }
`;

export const StyledConditons = styled.div`
  font-size: 16px;
  width: 1200px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #003861;
  margin-left: 7.8%;

  @media ${device.desktop} {
    font-size: 16px;
    width: 1100px;
    margin-left: 5.8%;
  }
  @media ${device.tabletLarge} {
    font-size: 16px;
    width: 900px;
    margin-left: 6.5%;
  }
  @media ${device.tablet} {
    font-size: 15px;
    width: 680px;
    margin-left: 7%;
  }
  @media ${device.mobileLarge} {
    font-size: 14px;
    width: 540px;
    margin-left: 7%;
  }
  @media ${device.mobile} {
    font-size: 12px;
    width: 200px;
    margin-left: 26%;
  }
  @media print {
    width: 94%;
    font-size: 16px;
    // margin-left: -5%;
  }
`;

export const StyledSpan = styled.label`
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #003861;
  margin-left: 12%;
  @media ${device.desktop} {
    margin-left: 10.5%;
  }
  @media ${device.tabletLarge} {
    margin-left: 11.5%;
  }
  @media ${device.tablet} {
    width: 400px;
    margin-left: 14%;
  }
  @media ${device.mobileLarge} {
    width: 280px;
    margin-left: 16%;
  }
  @media ${device.mobile} {
    font-size: 14px;
    width: 280px;
  }
  @media print {
    width: 50%;
    font-size: 16px;
    margin-left: 4%;
  }
`;

export const StyledReviewBox = styled.textarea`
  width: 1295px;
  height: 230px;
  border-radius: 5px;
  border: 1px solid #c9c8c8;
  box-shadow: 0px 0px 5.099999904632568px 0px #0000001c;
  padding-left: 2%;
  padding-top: 2%;
  font-size: 18px;
  margin-left: 6.8%;
  @media ${device.desktop} {
    margin-left: 5%;
    width: 1130px;
  }
  @media ${device.tabletLarge} {
    width: 933px;
    margin-left: 6%;
    height: 230px;
  }
  @media ${device.tablet} {
    width: 699px;
    margin-left: 6%;
    height: 230px;
    font-size: 16px;
  }
  @media ${device.mobileLarge} {
    width: 537px;
    margin-left: 6.2%;
    height: 92px;
    font-size: 15px;
  }
  @media ${device.mobile} {
    margin-left: 14%;
    width: 262px;
    height: 230px;
    font-size: 15px;
  }
`;

export const StyledReviewButton = styled.button`
  width: 250px;
  height: 60px;
  border: 1px solid #03275e;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  background: white;

  &:hover {
    box-shadow: 0.3rem 2.2rem 3.7rem 0rem rgba(0, 0, 0, 0.11),
      0rem -0.2rem 1rem 0rem rgba(0, 0, 0, 0.06);
    transform: scale(1.02);
    cursor: pointer;
    color: white;
    background: #03275e;
  }
  @media ${device.tabletLarge} {
    width: 250px;
    height: 56.59px;
    font-size: 20px;
  }
  @media ${device.tablet} {
    width: 200px;
    height: 56.59px;
    font-size: 18px;
  }
  @media ${device.mobileLarge} {
    width: 180px;
    height: 51.17px;
    font-size: 16px;
  }
  @media ${device.mobile} {
    width: 240px;
    height: 45px;
    font-size: 12px;
  }
`;

export const StyledLabelButtonDiv = styled.div`
  width: 89%;

  @media ${device.desktop} {
    width: 90%;
  }
  @media ${device.tabletLarge} {
    width: 89%;
  }
  @media ${device.tablet} {
    width: 88%;
  }
  @media ${device.mobileLarge} {
    width: 84%;
  }
  @media ${device.mobile} {
    width: 74%;
  }
`;

export const StyledRadioDiv = styled.div`
  width: 1125px;
  margin-left: 11%;
  @media ${device.desktop} {
    width: 1010px;
    margin-left: 110px;
  }
  @media ${device.tabletLarge} {
    width: 890px;
    margin-left: 80px;
  }
  @media ${device.tablet} {
    width: 680px;
    margin-left: 50px;
  }
  @media ${device.mobileLarge} {
    width: 560px;
    margin-left: 40px;
  }
  @media ${device.mobile} {
    width: 240px;
    margin-left: 17%;
  }

  @media print {
    width: 95%;
    margin-left: 12%;
    // background-color: yellow;
  }
`;

export const StyledRadioLabelDiv = styled.div`
  padding-left: 7.8%;

  @media ${device.desktop} {
    padding-left: 5.5%;
  }
  @media ${device.mobile} {
    padding-left: 14%;
  }
  @media print {
    margin-left: -7%;
    // background-color: yellow;
  }
`;
// style={{
//   paddingLeft: isMobileDevice
//     ? "14%"
//     : isDesktopDevice
//     ? "5.5%"
//     : "7.8%",
// }}

export const StyledRadioDiv2 = styled.div`
  width: 1125px;
  margin-left: 11%;
  @media ${device.desktop} {
    width: 1000px;
    margin-left: 110px;
  }
  @media ${device.tabletLarge} {
    width: 900px;
    margin-left: 80px;
  }
  @media ${device.tablet} {
    width: 680px;
    margin-left: 50px;
  }
  @media ${device.mobileLarge} {
    width: 560px;
    margin-left: 40px;
  }
  @media ${device.mobile} {
    width: 240px;
    margin-left: 17%;
  }

  @media print {
    width: 94%;
    margin-left: 3%;
  }
`;
