import React from 'react'
import {
    GrantSecTop,
    GrantHeaderSec,
    Logo,
    StyledGrantHeading,
    TitleGrantHeading,
    ParaGrantHeading,
    StyledButton,
    Button
} from './style'
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
    const navigate = useNavigate();
    const handleButtonClick = () => {
      // navigate("/user/form");
      window.open("/user/form", "_blank");
        // Scroll to the top of the page
        // window.scrollTo({ top: 0, behavior: "smooth" });
      };
  return (
    <GrantSecTop>
      <GrantHeaderSec>
        <a href="https://liamcrest.com/">
          
          <Logo src="../liamcrestlogo.png" alt="logo image" />
        </a>
      </GrantHeaderSec>
      <StyledGrantHeading>
        <TitleGrantHeading>
          Liam Crest Community Service <span> Grant </span> Program
        </TitleGrantHeading>
        <ParaGrantHeading>
          The Liam Crest Community Service Grant program works with eligible
          organizations to offer a service grant to help cover the cost of
          services related to technology and education.
        </ParaGrantHeading>
        <StyledButton>
          <Button onClick={handleButtonClick}>APPLY FOR GRANT</Button>
        </StyledButton>
      </StyledGrantHeading>
    </GrantSecTop>
  );
}

export default HeroSection;