import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Components/LandingPage/Main";
import Dashboard from "./Components/Dashboard";
import ApplicationForm from "./Components/Form";
import LoginCard from "./Components/Login";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/admin/login" element={<LoginCard />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/user/form" element={<ApplicationForm />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
