import React,{useState} from "react";
import {
  StyledCopyRight,
  StyledFooter,
  StyledImg,
  StyledInfo,
  StyledInfoPara,
  StyledItem,
  StyledList,
  StyledLogo,
} from "./style";
import { useNavigate } from 'react-router-dom';
import ContactFormPopup from '../Contact'

function Footer() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const navigate = useNavigate();
  const handleButtonClick = () => {
    // navigate("/user/form");
    window.open("/user/form", "_blank");
      // Scroll to the top of the page
      // window.scrollTo({ top: 0, behavior: "smooth" });
    };

  return (
    <StyledFooter>
      <StyledLogo>
        <StyledImg src="./footerlogo.png" alt="logo" />
        <StyledCopyRight>
          © 2024 — <span>Liam Crest</span>. All Rights Reserved.
        </StyledCopyRight>
      </StyledLogo>
      <StyledInfo>
        <StyledInfoPara>
          Address: Memphis, TN & Phoenix, AZ, +1-888-491-5293, +1-312-801-0900
          Sales@liamcrest.com, Grant@liamcrest.com
        </StyledInfoPara>
        <StyledList>
          <StyledItem>
            {isFormOpen && <ContactFormPopup onClose={closeForm} />}
            <a onClick={openForm} href="#Contact">
              GET IN TOUCH
            </a>
          </StyledItem>
          <StyledItem>
            <a onClick={handleButtonClick}>APPLY</a>
          </StyledItem>
          {/* <StyledItem>
            <a href="#Terms">TERMS OF SERVICE</a>
          </StyledItem> */}
        </StyledList>
      </StyledInfo>
    </StyledFooter>
  );
}

export default Footer;
