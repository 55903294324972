/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./dashboard.css";
import logo from "./images/logo.png";
import TableComponent from "./table";
const Dashboard = () => {
  return (
    <>
      <div className="top-left-container">
        <img
          className="top-left-image"
          src={logo} // Replace with the path to your image
          alt="Top Left Image"
        />
      </div>
      <TableComponent />
    </>
  );
};

export default Dashboard;
