import styled from "styled-components";

export const GrantSecTop=styled.div`
background-image: url('../bg.png');
width:100%;
background-size: cover;
background-repeat: no-repeat;
display: flex;
flex-direction: column;
height: 100vh; 
background-position: center;
@media only screen and (min-width:1281px) and (max-width:1536px){
 height:777.86px;   
}
@media only screen and (min-width:1081px) and (max-width:1280px){
 height:659.42px;   
}
@media only screen and (min-width:811px) and (max-width:1080px){
 height:536px;   
}
@media only screen and (min-width:641px) and (max-width:810px){
 height:469px;   
}
@media only screen and (min-width:320px) and (max-width:640px){
 
    height:361px;  

}
`;
export const GrantHeaderSec=styled.div`
display: flex;
padding:8px;
margin-left: 2%;
`;
export const Logo=styled.img`
width:125px;
height:115px;
@media only screen and (min-width:1281px) and (max-width:1536px){
    width:110px;
height:102px;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
    width:101px;
height:93px;
}
@media only screen and (min-width:811px) and (max-width:1080px){
    width:81px;
height:75px;
}
@media only screen and (min-width:641px) and (max-width:810px){
    width:68px;
height:62px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width:63px;
height:57px;
}
`;
export const StyledGrantHeading=styled.div`
margin-left:10%;
margin-top:1%;
@media only screen and (min-width:1281px) and (max-width:1536px){
    margin-top:5%;
    margin-left: 11%;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
    margin-top:5%;
    margin-left: 10%;
}
@media only screen and (min-width:811px) and (max-width:1080px){
    margin-top:5%;
    margin-left: 12%;
}
@media only screen and (min-width:641px) and (max-width:810px){
    margin-top:6%;
    margin-left: 12%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    margin-top:11%;
    margin-left: 13%;
}
`;
export const TitleGrantHeading=styled.h2`
font-family:'Montserrat';
font-weight:700;
font-size:70px;
line-height:72px;
text-transform: uppercase;
color:#03275E;
width:50%;
letter-spacing: normal;
span{
    color:#ED910C;
}
@media only screen and (min-width:1281px) and (max-width:1536px){
    font-size:66px;
line-height:64px;
width:50%;


}
@media only screen and (min-width:1081px) and (max-width:1280px){
    font-size:56px;
line-height:51px;
width:55%;
}
@media only screen and (min-width:811px) and (max-width:1080px){
    font-size:42px;
line-height:42px;
width:55%;
}
@media only screen and (min-width:641px) and (max-width:810px){
    font-size:36px;
line-height:33px;
width:60%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    font-size:20px;
line-height:20px;
width:59%;
}
`;
export const ParaGrantHeading=styled.p`
font-family:'Poppins';
font-weight:400;
font-size:20px;
line-height:35px;
color:#000000;
width:60%;
@media only screen and (min-width:1281px) and (max-width:1536px){
    font-size:16px;
    line-height:30px;
    width:50%;
}
@media only screen and (min-width:1081px) and (max-width:1280px){
    font-size:16px;
    line-height:30px;
    width:50%;
}
@media only screen and (min-width:811px) and (max-width:1080px){
    font-size:14px;
    line-height:21px;
    width:50%;
}
@media only screen and (min-width:641px) and (max-width:810px){
    font-size:12px;
    line-height:20px;
    width:50%;
}
@media only screen and (min-width:320px) and (max-width:640px){
    font-size:8px;
    line-height:13px;
    width:55%;
}
`;
export const StyledButton=styled.div``;
export const Button=styled.button`
width:279.07px;
height:65px;
border-radius: 5px;
cursor: pointer;
text-align: center;
background: #03275e;
border:none;
font-family:'Poppins';
font-weight:500;
font-size:20px;
line-height:30px;
color:#FFFFFF;
&:hover{
    background: #ED910C;
    transition: 0.5s;
}
@media only screen and (min-width:1281px) and (max-width:1536px){
    width:247.07px;
    height:57px;
  
}
@media only screen and (min-width:1081px) and (max-width:1280px){
    width:209.07px;
    height:48px;
    font-size:16px;  
}
@media only screen and (min-width:811px) and (max-width:1080px){
    width:198.07px;
    height:51px;
    font-size:16px;
}
@media only screen and (min-width:641px) and (max-width:810px){
    width:185.07px;
    height:46px;
    font-size:16px;  
}
@media only screen and (min-width:320px) and (max-width:640px){
    width:130.07px;
    height:33px;
    font-size:10px;
}
`;