export const InputCss = {
  halfWidth: {
    width: "634px",
  },
  fullWidht: {
    width: "1295px",
  },
  radioWidth: {
    width: "349px",
  },
  desktopHalfWidth: {
    width: "555px",
  },
  desktopFullWidht: {
    width: "1130px",
  },
  tabletLargeHalfWidth: {
    width: "456px",
  },
  tabletLargeFullWidth: {
    width: "933px",
  },
  tabletHalfWidth: {
    width: "342px",
  },
  tabletFullWidth: {
    width: "699px",
  },
  mobileLargeWidth: {
    width: "537px",
  },
  mobileWidth: {
    width: "262px",
  },
};
