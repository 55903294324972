import React, { useState } from "react";

import { Row, Col } from "react-bootstrap";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  StyledExpandButton,
  StyledAmountDiv,
  StyledAmountText,
  StyledClearButton,
  StyledDisclaimer,
  StyledDisclaimerHeading,
  StyledDisclaimerText,
  StyledPriceTags,
  StyledDividers,
  StyledPriceText,
  StyledCalculator,
  StyledPriceDiv,
  StyledTotalPriceTag,
} from "./style";

export default function GrantCalculator() {
  const isDesktopDevice = window.innerWidth <= 1280;
  const isTabletLargeDevice = window.innerWidth <= 1200;
  const isTabletDevice = window.innerWidth <= 1023;
  const isMobileLargeDevice = window.innerWidth <= 767;
  const isMobileDevice = window.innerWidth <= 480;
  const [checkedItems, setCheckedItems] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  // temp slider styles
  const CustomSlider = styled(Slider)({
    color: "transparent",
    height: 20, // Line height
    "& .MuiSlider-track": {
      // Styles for the selected part of the slider
      backgroundColor: "rgba(32, 158, 158, 1)", // Selected part color
      height: 16, // Selected part height
    },
    "& .MuiSlider-rail": {
      // Styles for the remaining part of the slider
      backgroundColor: "rgba(32, 155, 155, 0.17)", // Remaining part color
      height: 16, // Remaining part height
    },
    "& .MuiSlider-thumb": {
      // Styles for the slider thumb
      width: 50,
      height: 50,
      background: `url("/FormImages/downArrow.png")`,
      border: "none",
      transform: "translate(-50%, -50%)",
      "&:hover": {
        boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.16)",
      },

      "&:before": {
        display: "none",
      },
      // "&:after": {
      //   display: "none",
      // },
    },
    "& .MuiSlider-valueLabel": {
      color: "Black",
      backgroundColor: "white",
    },
  });

  const StyledMenu = styled((props) => (
    <Menu
      elevation={1}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
      disableScrollLock={true}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      // position: "absolute !important",
      overflow: "auto !important",
      maxHeight: "100vh",
      borderRadius: 10,
      marginTop: theme.spacing(1),
      width: isMobileDevice
        ? 280
        : isMobileLargeDevice
        ? 537
        : isTabletDevice
        ? 440
        : isTabletLargeDevice
        ? 580
        : isDesktopDevice
        ? 740
        : 840,
      color: "#003861",
    },
    "& .MuiMenu-paper": {
      backgroundColor: "white",
    },
    "& .MuiMenuItem-root": {
      "&:hover": {
        backgroundColor: "white",
      },
    },
  }));

  const [total, setTotal] = useState(0);
  const handleChange = (event) => {
    const { name, checked } = event.target;
    const value = parseInt(event.target.value);

    // Update checkedItems state with new checked value
    setCheckedItems({ ...checkedItems, [name]: checked });

    // Update total based on checkbox checked or unchecked
    if (checked) {
      setTotal((prevTotal) => prevTotal + value);
    } else {
      setTotal((prevTotal) => prevTotal - value);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    //alert("invoked");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <StyledCalculator className=" mt-4">
      <Row className="g-0">
        <Col lg={8} md={8} sm={12} xs={12} className="">
          <div className="">
            <StyledExpandButton
              id="about-button"
              aria-controls={open ? "about-button" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={(e) => {
                handleClick(e);
              }}

              //   endIcon={<ExpandButtonIcon />}
            >
              Choose Services
              <ExpandMoreIcon />
            </StyledExpandButton>
            <StyledMenu
              id="about-button"
              MenuListProps={{
                "aria-labelledby": "about-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuProps={{ disableScrollLock: true }}
            >
              <MenuItem onClick={handleClose} disableRipple>
                <FormGroup
                  sx={{
                    paddingLeft: isMobileDevice ? 1 : 5,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems.$12k}
                        onChange={handleChange}
                        name="$12k"
                        value={12000}
                        // color="success"
                        style={{ color: " #DF870C" }}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: isMobileDevice ? "14px" : "",
                          textWrap: "wrap",
                        }}
                      >
                        Online Training Development Services <br />
                        <span style={{ color: "#DF870C" }}>
                          ($12k per eLearning hour)
                        </span>
                      </span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems.$100k}
                        onChange={handleChange}
                        name="$100k"
                        value={50000}
                        style={{ color: " #DF870C" }}
                        // color="success"
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: isMobileDevice ? "14px" : "",
                          textWrap: "wrap",
                        }}
                      >
                        Software Development Services <br />
                        <span style={{ color: "#DF870C" }}>
                          ($50k starting price)
                        </span>
                      </span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems.$2k}
                        onChange={handleChange}
                        name="$2k"
                        value={10000}
                        // color="success"
                        style={{ color: " #DF870C" }}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: isMobileDevice ? "14px" : "",
                          textWrap: "wrap",
                        }}
                      >
                        Website Development Services <br />
                        <span style={{ color: " #DF870C" }}>
                          ($10k Starting Price)
                        </span>
                      </span>
                    }
                  />
                  {/* video segment */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems.$25k}
                        onChange={handleChange}
                        name="$25k"
                        value={10000}
                        // color="success"
                        style={{ color: " #DF870C" }}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: isMobileDevice ? "14px" : "",
                          textWrap: "wrap",
                        }}
                      >
                        Animated Videos <br />
                        <span style={{ color: " #DF870C" }}>
                          ($10k per 2-3 mins 2D )
                        </span>
                      </span>
                    }
                  />
                </FormGroup>
              </MenuItem>
            </StyledMenu>
          </div>
          <StyledAmountDiv
            className="mt-4 d-flex flex-column justify-content-center"
            height="175px"
          >
            <div className="d-flex flex-row justify-content-between">
              <StyledAmountText>Choose Amount</StyledAmountText>
              <StyledClearButton>Clear All</StyledClearButton>
            </div>
            <CustomSlider
              className={isMobileDevice ? "ms-2" : ""}
              aria-label="Restricted values"
              defaultValue={total}
              step={2000}
              min={0}
              max={1500000}
              valueLabelDisplay="auto"
              // readOnly
              disabled
            />

            <StyledTotalPriceTag className="mt-1 d-flex justify-content-end">
              $1.5M
            </StyledTotalPriceTag>
          </StyledAmountDiv>
          <StyledPriceDiv className="mt-4 d-flex flex-row">
            <Row className=" g-0" style={{ width: "100%" }}>
              <Col
                lg={4}
                md={4}
                sm={4}
                xs={4}
                style={{ height: "101px" }}
                className="d-flex flex-row"
              >
                <div
                  className={
                    isTabletDevice || isTabletLargeDevice
                      ? "d-flex flex-column justify-content-center align-items-center"
                      : "d-flex flex-row align-items-center "
                  }
                  style={{ height: "101px", width: "100%" }}
                >
                  <StyledPriceText>Total Price</StyledPriceText>
                  <StyledPriceTags
                    className={
                      isTabletDevice ? "" : isTabletLargeDevice ? "" : "ps-4"
                    }
                  >
                    ${total}
                  </StyledPriceTags>
                </div>
                <StyledDividers
                  className={
                    isMobileDevice ? "ms-1" : isTabletDevice ? "ms-4" : "ms-2"
                  }
                />
              </Col>
              <Col
                lg={3}
                md={3}
                sm={3}
                xs={3}
                className={
                  isTabletDevice || isTabletLargeDevice
                    ? "px-4 d-flex flex-column justify-content-center align-items-center"
                    : "d-flex flex-row align-items-center "
                }
              >
                <StyledPriceText
                  className={
                    isTabletDevice ? "" : isTabletLargeDevice ? "" : "ms-2 ps-4"
                  }
                >
                  Discount
                </StyledPriceText>
                <StyledPriceTags className={isTabletDevice ? "" : "px-4"}>
                  0
                </StyledPriceTags>
              </Col>
              {/* <StyledDividers className={isTabletDevice ? "d-none" : ""} /> */}

              <Col
                lg={5}
                md={5}
                sm={5}
                xs={5}
                className={
                  isTabletDevice || isTabletLargeDevice
                    ? "d-flex flex-column justify-content-center align-items-center"
                    : " d-flex flex-row align-items-center "
                }
                style={{
                  background: "#249B9F",

                  // width: isTabletDevice ? "49.3%" : "42.86%",
                }}
              >
                <StyledPriceText
                  className={
                    isTabletDevice ? "" : isTabletLargeDevice ? "ps-4" : "ps-5"
                  }
                  style={{ color: "white" }}
                >
                  Net Price
                </StyledPriceText>
                <StyledPriceTags
                  className={isTabletDevice ? "" : "ps-4"}
                  style={{ color: "white" }}
                >
                  ${total}
                </StyledPriceTags>
              </Col>
            </Row>
          </StyledPriceDiv>
        </Col>
        <Col
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className={isMobileLargeDevice ? "mt-5" : ""}
        >
          <StyledDisclaimer className="pt-3">
            <Row>
              <Col className="p-5">
                <StyledDisclaimerHeading>Disclaimer*</StyledDisclaimerHeading>
                <StyledDisclaimerText className="mt-3">
                  The Liam Crest Grant Service Calculator estimates total cost
                  of services. The product price and grant estimate are not
                  exact and subject to review by Liam Crest sales and grant
                  departments.
                </StyledDisclaimerText>
              </Col>
            </Row>
          </StyledDisclaimer>
        </Col>
      </Row>
    </StyledCalculator>
  );
}
