import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { BaseURL } from "../../variables";
function TableComponent() {
  const navigate = useNavigate();

  const columnNames = [
    "Form #",
    "Organization Name",
    "Organization Type",
    "Phone Number",
    "Role",
    "Status",
    "Admin's review",
    "View details",
  ];
  const [appList, setAppList] = useState([]);
  let count = 2670;
  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      navigate("/admin/login");
    }
    const fetchData = async () => {
      // -----------
      axios
        .get(`${BaseURL}grantApplication/list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          setAppList(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error.message);
        });
    };

    fetchData();
  }, []);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const formatDate = (date, addDay = false) => {
    if (date) {
      const formattedDate = new Date(date);
      if (addDay) {
        formattedDate.setDate(formattedDate.getDate() + 1);
      }
      return formattedDate.toISOString().split("T")[0];
    }
    return null;
  };
  const handleSearch = () => {
    const formattedStartDate = formatDate(startDate, true);
    const formattedEndDate = formatDate(endDate, true);
    console.log(formattedStartDate, formattedEndDate);
    axios
      .get(`${BaseURL}grantApplication/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        },
      })
      .then((response) => {
        console.log(response);
        setAppList(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      });
  };

  const handleDetails = (id) => {
    // alert(id);

    navigate("/user/form?id=" + id);
  };

  return (
    <div className="container" style={{ maxHeight: "900px" }}>
      <section className="intro">
        <div>
          <div
          // className="mask d-flex align-items-center h-100"
          // style={{ backgroundImage: "rgba(25, 185, 234,.25)" }}
          >
            <div>
              <div className="btn_containers">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-1 srch_containers"></div>
                  <div className="col-lg-2 col-md-1 col-sm-1 srch_containers">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Start Date"
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-2 col-md-1 col-sm-1 srch_containers">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText="End Date"
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 srch_containers">
                    <Button variant="primary" onClick={handleSearch}>
                      Search
                    </Button>
                  </div>

                  <div className="col-lg-1 col-md-1 col-sm-1 srch_containers">
                    <Button
                      variant="primary"
                      onClick={() => {
                        localStorage.clear();
                        navigate("/admin/login");
                      }}
                    >
                      Logout
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="row justify-content-center"
                style={{ backgroundColor: "white", marginTop: "5.8%" }}
              >
                <div className="col-12" style={{ backgroundColor: "white" }}>
                  <div
                    className="card cardz"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="card-body"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        className="table-responsive"
                        style={{
                          maxHeight: "600px",
                          overflowY: "auto",
                        }}
                      >
                        <table className="table table-hover mb-0">
                          <thead>
                            <tr>
                              {columnNames.map((columnName, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  style={{
                                    background: "#007bff",
                                    color: "white",
                                  }}
                                >
                                  {columnName}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          {appList.length > 0 ? (
                            <tbody>
                              {appList.map((item, index) => (
                                <tr key={index}>
                                  <td>{count++}</td>
                                  <td>{item.organization_name}</td>
                                  <td>{item.organization_type}</td>
                                  <td>{item.phone_number}</td>
                                  <td>{item.role}</td>

                                  <td>
                                    <Badge
                                      pill
                                      bg={
                                        item.status === "pending"
                                          ? "warning"
                                          : item.status === "Approved"
                                          ? "success"
                                          : item.status === "Incomplete"
                                          ? "primary"
                                          : "danger"
                                      }
                                      text="light"
                                    >
                                      {item.status}
                                    </Badge>
                                  </td>
                                  <td>{item.liamcrest_remarks}</td>
                                  <td>
                                    {" "}
                                    <Button
                                      variant="primary"
                                      onClick={() => {
                                        handleDetails(item.id);
                                      }}
                                    >
                                      View Details
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="5">Loading...</td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TableComponent;
