import styled from "styled-components";

export const StyledFooter = styled.div`
  width: 100%;
  height: 272px;
  background-color: #03275e;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  box-sizing: border-box;
  padding: 0 15%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    padding: 0 15%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    padding: 0 15%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    padding: 0 15%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    padding: 0 5%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const StyledLogo = styled.div`
  width: 25%;
  border-right: 1px solid white;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    border-right: none;
    width: 100%;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const StyledImg = styled.img`
  height: 119px;
  width: 130px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 105px;
    width: 115px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 88px;
    width: 97px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 107px;
    width: 118px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 85px;
    width: 95px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 75px;
    width: 84px;
  }
`;
export const StyledCopyRight = styled.p`
  width: 179px;
  height: 40px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #ffffff8c;
  text-align: left;
  span {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    color: #fd9400;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 150px;
    height: 34px;
    font-size: 14px;
    span {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 127px;
    height: 29px;
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 135px;
    height: 40px;
    font-size: 12px;
    span {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 126px;
    height: 35px;
    font-size: 11px;
    span {
      font-size: 11px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 114px;
    height: 30px;
    font-size: 10px;
    line-height: 15px;
    margin-left: 10%;
    span {
      font-size: 10px;
      line-height: 15px;
    }
  }
`;
export const StyledInfo = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
  }
`;

export const StyledInfoPara = styled.p`
  width: 38%;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 12px;
    line-height: 28px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
    line-height: 28px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    line-height: 28px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 50%;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const StyledList = styled.div`
  padding: 2% 0;
  box-sizing: border-box;
`;

export const StyledItem = styled.p`
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  a {
    text-decoration: none;
    color: #ffffff;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 14px;
    line-height: 32px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
    line-height: 28px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    line-height: 24px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
    line-height: 20px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    line-height: 18px;
  }
`;
