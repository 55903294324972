import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
`;

export const VideoControls = styled.div`
  color: #DF870C;
`;

export const Container = styled.div`
  text-align: center;
  margin-top: 50px;
  overflow:hidden;
`;

export const Heading = styled.h2`
    color: #DF870C;
    margin-top: 45px;
    font-size:32px;
    font-family:Montserrat;
    font-weight:700;
    color:#070606;
    @media only screen and (min-width: 1081px) and (max-width: 1280px) {
      font-size: 34px;
      line-height: 28px;
    }
    @media only screen and (min-width: 811px) and (max-width: 1080px) {
      font-size: 28px;
      line-height: 24px;
    }
    @media only screen and (min-width: 641px) and (max-width: 810px) {
      font-size: 22px;
      line-height: 20px;
      padding:0px 10px 10px 10px;
    }
    @media only screen and (min-width: 320px) and (max-width: 640px) {
      font-size: 18px;
      line-height: 18px;
      padding:0px 10px 10px 10px;
    }
`;
export const ParagraphContainer = styled.div`
  max-width: 900px; /* Set your desired max width for the paragraph */
  margin: 0 auto; /* Center the container horizontally */
`;
export const Paragraph = styled.p`
  color: ##000000;
  font-size:18px;
  font-weight:600;
  text-align:center;
  padding:10px 0 5px 0px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
    line-height: 20px;
    padding:0px 20px 10px 20px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    line-height: 18px;
    padding:0px 20px 10px 20px;
  }

`;

export const Button = styled.button`
  background-color: #DF870C;
  color: #070606;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
`;


export const BackgroundImage = styled.div`
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('Rectangle-30.png'); /* Replace with your image URL */
  background-size: cover;
  background-position: 100%;
  z-index: -1; /* Ensure it's below the overlay */
  opacity: 100%;
  aspect-ratio: 100%;

`;
export const StyledVideo = styled.video`
  width: 75%;
  max-width: 100%;
  height: 60%;
  border: none;
  &:hover {
    cursor: pointer;
  }
  
`;

export const VideoOverlay = styled.div`
  width: 75%;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 40px;
  padding: 50px;
  position: relative;
`;
