import styled, { css } from "styled-components";

export const FAQTop = styled.div`
  height: auto;
  background: url("../bg3.png");
  padding-bottom: 3%;
  background-size: cover;

  background-repeat: no-repeat;
  background-position: bottom;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: auto;
    margin-top: 3%;
    padding-bottom: 3%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-top: 8%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 0%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    margin-top: 0%;
  }
`;
export const FaqB = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const FAQHeading = styled.div`
  margin-top: 15%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    margin-top: 6%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin-top: 5%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-top: 0%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 8%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 8%;
  }
`;
export const Title = styled.h2`
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 32px;
  line-height: 39.01px;
  color: #000000;
  text-transform: uppercase;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 32px;
    line-height: 39.01px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 28px;
    line-height: 34.01px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
    line-height: 29.01px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
    line-height: 24.01px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
    line-height: 17.01px;
    text-align: center;
    width: 87%;
  }
`;
export const FAQList = styled.div`
  cursor: pointer;
`;
export const StyledItems = styled.div``;
export const Data = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const ServiceCard = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  height: auto;
  border: ${({ open }) =>
    open ? "1px solid rgba(3, 39, 94, 0.3)" : "1px solid #03275E"};
  border-radius: 5px;
  padding: 20px;
  transition: height 0.3s ease;
  margin-top: 3%;
  width: 1100px;
  ${({ open }) =>
    open &&
    css`
      height: auto;
    `}
  @media only screen and (min-width:1281px) and (max-width:1536px) {
    width: 933.07px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 791px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 594px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 472.85px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 255.85px;
  }
`;
export const ServiceText = styled.h4`
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  letter-spacing: 0.9px;
  width: 85%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    line-height: 17.01px;
    text-align: left;
  }
`;
export const ArrowIcon = styled.div`
  margin-left: auto;
`;
export const Description = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  flex-direction: column;
  font-size: 20px;
  line-height: 41px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #000000;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 16px;
    line-height: 36px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
    line-height: 28px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
    line-height: 27px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
    line-height: 27px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    line-height: 16.01px;
  }
`;
