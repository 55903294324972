import Footer from "../Footer";
import HeroSection from "../HeroSection";
import Service from "../Service";
import FAQ from "../FAQ";
import CaseStudies from "../CaseStudies";
import OurClient from "../OurClient";
import GrantSection from "../GrantSection";
function Main() {
  return (
    <>
      <HeroSection />
      <Service />
      <CaseStudies />
      <OurClient />
      <GrantSection />
      <FAQ />
      <Footer />
    </>
  );
}

export default Main;
