import styled from "styled-components";
export const Section = styled.section`
  width: 100%;
  /* Set your desired max width */

  @media only screen and (min-width: 1537px) {
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
  }
  @media only screen and (min-width: 414px) and (max-width: 640px) {
  }
  @media only screen and (min-width: 340px) and (max-width: 413px) {
  }
`;

export const UpperImage = styled.img`
  width: 100%;
  height: 100%; /* Maintain aspect ratio */
  margin-top: 80px;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    margin-top: 38px;
  }
`;

export const LowerBackgroundImage = styled.div`
  background-image: url("Background_Image.png");
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-position: top;
  transform: translateY(-40px);
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    background-size: cover;
  }
`;

export const OverlayContent = styled.div`
  padding-top: 50px;
  text-align: center;
  color: #df870c; /* Set text color */
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  margin: 70px 0 0 0;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 40px;
    margin-top: 4%;
    margin: 47px 0 0 0;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    gap: 20px;
    margin-top: 4%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    gap: 10px;
    margin-top: 3%;
  }

  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    line-height: 18px;
    margin: 0px 20px 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
  }
`;

export const Card = styled.div`
  width: 364.49px;
  height: 500px;
  background-color: #fff;
  padding: 2px;
  border-radius: 20px;
  border: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: justify;
  &:hover {
    box-shadow: 0px 0px 8px 22px rgba(0, 0, 0, 0.1);
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 310px;
    height: 442px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 262px;
    height: 369px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 197px;
    height: 349px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 190px;
    height: 300px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 185px;
    height: 300px;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 390px; /* Set your desired height */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 20px;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 350px; /* Set your desired height */
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 220px; /* Set your desired height */
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 250px; /* Set your desired height */
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 210px; /* Set your desired height */
  }
`;

export const CardData = styled.div`
  padding: 15px;
  height: 220px;
  margin-top: -39%;
  position: relative;
  z-index: 999;
  background: #ffffff;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 198px; /* Set your desired height */
    margin-top: -57%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 155px;
    margin-top: -59%; /* Set your desired height */
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 155px;
    margin-top: -40%; /* Set your desired height */
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 148px;
    margin-top: -60%; /* Set your desired height */
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 148px;
    margin-top: -49%;
  }
`;

export const CardHeading = styled.h3`
  margin: 15px 0;
  font-size: 18px;
  line-height: 25.74px;
  color: #03275e;
  font-family: "Poppins";
  font-weight: 700;
  width: 75%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 16px;
    line-height: 22.02px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
    line-height: 20.02px;
    width: 80%;
    margin: 4px 0;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 14px;
    line-height: 20.02px;
    width: 106%;
    margin: 4px 0;
    text-align: left;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 11px;
    line-height: 15.73px;
    width: 106%;
    margin: -2px 0;
    text-align: left;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 11px;
    line-height: 15.73px;
    width: 100%;
    margin: -2px 0;
    text-align: left;
  }
`;

export const CardParagraph = styled.p`
  color: #03375e;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.88px;
  font-family: "Poppins";
  width: 88%;
  text-align: left;

  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 14px;
    line-height: 20.02px;
    width: 85%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
    line-height: 17.02px;
    width: 95%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 11px;
    line-height: 15.02px;
    width: 93%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 10px;
    line-height: 14.01px;
    margin-top: 4px;
    width: 91%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    line-height: 14.01px;
    margin-top: 4px;
  }
`;

export const CardLink = styled.a`
  color: #df870c;
  font-family: "poppins";
  text-decoration: underline;
  display: block;
  margin: 10px 0 15px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
    font-family: "poppins";
    margin-top: -2%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 17.12px;
    font-family: "poppins";
    margin-top: -4%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 17.12px;
    font-family: "poppins";
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 10px;
    font-weight: 400;
    line-height: 14.12px;
    font-family: "poppins";
    margin-top: -8px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    font-weight: 400;
    line-height: 14.12px;
    font-family: "poppins";
    margin-top: 0px;
  }
`;
export const Heading = styled.h2`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  line-height: 29.19px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 24px;
    line-height: 29.19px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 20px;
    line-height: 24.19px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 20px;
    line-height: 24.19px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 16px;
    line-height: 19.5px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    line-height: 12.19px;
  }
`;
export const ParaHeading = styled.h2`
  color: #df870c;
  margin-top: 10px;
  font-size: 32px;
  font-family: "Montserrat";
  font-weight: 700;
  line-height: 39.01px;
  color: #070606;
  text-align: center;
  text-transform: uppercase;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 28px;
    line-height: 34.13px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
    line-height: 29.26px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
    line-height: 24.38px;
    padding: 0 22%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 18px;
    line-height: 18px;
    padding: 0px 10px 20px 10px;
  }
`;
export const ParagraphContainer = styled.div`
  max-width: 650px; /* Set your desired max width for the paragraph */
  margin: 0 auto; /* Center the container horizontally */
`;
