import React from "react";
import { StyledButton, StyledH2, StyledMain, StyledText } from "./styles";
import { useNavigate } from 'react-router-dom';

function GrantSection() {

  const navigate = useNavigate();
  const handleButtonClick = () => {
    // navigate("/user/form");
    window.open("/user/form", "_blank");
      // Scroll to the top of the page
      // window.scrollTo({ top: 0, behavior: "smooth" });
    };

  return (
    <StyledMain>
      <StyledText>
        <StyledH2>
          Apply for the Grants that{" "}
          <span>
            Align with your Origanizations Purpose and community needs.
          </span>
        </StyledH2>
        <StyledButton onClick={handleButtonClick}>APPLY FOR GRANT</StyledButton>
      </StyledText>
    </StyledMain>
  );
}

export default GrantSection;
