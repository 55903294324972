import React from "react";
import VideoComponent from "./VideoComponent";
import {
  Section,
  UpperImage,
  LowerBackgroundImage,
  CardContainer,
  CardLink,
  CardParagraph,
  CardHeading,
  CardData,
  CardImage,
  OverlayContent,
  ParagraphContainer,
  Card,
  ParaHeading,
  Heading,
} from "./style";

const CaseStudies = () => {
  return (
    <Section>
      <UpperImage src="Group.png" alt="Description of the upper image" />
      <LowerBackgroundImage>
        <OverlayContent>
          <Heading>Grant Case Studies</Heading>
          <ParagraphContainer>
            <ParaHeading>
              Why should your organization work with Liam Crest?
            </ParaHeading>
          </ParagraphContainer>
          <CardContainer>
            <a
              style={{ textDecoration: "none", cursor: "pointer" }}
              href="https://nebraska.liamcrest.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Card>
                <CardImage src="image 97.png" alt="Card 1" />
                <CardData>
                  <CardHeading>Training Partners</CardHeading>
                  <CardParagraph>
                    Liam Crest manages and hosts their training center, creates
                    modules yearly for the organization, and handles sporadic
                    tech and design needs.
                  </CardParagraph>
                  <CardLink
                    href="https://nebraska.liamcrest.com/"
                    target="_blank"
                  >
                    Visit Website
                  </CardLink>
                </CardData>
              </Card>
            </a>
            <a
              style={{ textDecoration: "none", cursor: "pointer" }}
              href="https://sasta.fris.org/"
              target="_blank"
              rel="noreferrer"
            >
              <Card>
                <CardImage src="image 99.png" alt="Card 2" />
                <CardData>
                  <CardHeading>
                    eLearning and Learning Management System
                  </CardHeading>
                  <CardParagraph>
                    Liam Crest was able to apply for a grant of over $350,000 to
                    assist in revamping their entire training program.
                  </CardParagraph>
                  <CardLink href="https://sasta.fris.org/" target="_blank">
                    Visit Website
                  </CardLink>
                </CardData>
              </Card>
            </a>
            <a
              style={{ textDecoration: "none", cursor: "pointer" }}
              href="https://hotspotmapky.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Card>
                <CardImage src="98.png" alt="Card 3" />
                <CardData>
                  <CardHeading>Website / Software Work</CardHeading>
                  <CardParagraph>
                    Liam Crest applied for a grant for $70,000 and KASAP only
                    paid what their funding allowed. Our organizations have been
                    in a relationship over 4 years.
                  </CardParagraph>
                  <CardLink href="https://hotspotmapky.com/" target="_blank">
                    Visit Website
                  </CardLink>
                </CardData>
              </Card>
            </a>
          </CardContainer>
        </OverlayContent>
      </LowerBackgroundImage>
      <VideoComponent
        heading="What makes us different from others?"
        paragraph="Working with Liam Crest is a collaborative journey. Our team is dedicated to understanding your organization's unique needs, ensuring a seamless process from application to implementation. We prioritize communication, transparency, and a shared vision for success."
        video="Liam Crest Sales Full Video.mp4" // Replace with your actual video URL
        thumbnail="image-2.jpg"
      />
      <VideoComponent
        heading="What is it Like Working with Liam Crest?"
        paragraph="Liam Crest distinguishes itself by providing service grants on a sliding scale, ensuring that organizations in need receive tailored support. Our approach is rooted in understanding your impact on the community and how the requested services will empower others."
        video="Liam Crest Testimonial Full Video Rev 2 (1).mp4" // Replace with your actual video URL
        thumbnail="Testimonial thumb nail-01.jpg"
      />
    </Section>
  );
};
export default CaseStudies;
