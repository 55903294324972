/* eslint-disable eqeqeq */
import React,{useState} from 'react'
import {services} from '../../../utils/FaqData';
import {
    FAQTop,
    FaqB,
    FAQHeading,
    Title,
    FAQList,
    StyledItems,
    ServiceCard,
    ServiceText,
    ArrowIcon,
    Description
} from './style';
import {  FaChevronUp , FaChevronDown } from 'react-icons/fa';

const FreuentlyAskedQuestions = () => {

const [openService, setOpenService] = useState(null);
  const toggleDescription = (index) => {
    setOpenService(openService === index ? null : index);
  };

  return (
    <FAQTop>
      <FaqB>
        <FAQHeading>
          <Title>Frequently Asked Questions</Title>
        </FAQHeading>

        <FAQList>
          {services.map((service, index) => (
            <StyledItems key={index}>
              <ServiceCard
                open={openService === index}
                onClick={() => toggleDescription(index)}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  {" "}
                  <ServiceText>{service.title}</ServiceText>
                  <ArrowIcon style={{ marginTop: "2%" }}>
                    {openService === index ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </ArrowIcon>{" "}
                </div>
                <Description open={openService === index}>
                  {index < 2 ? (
                    <ul>
                      {service.description.map((desc, i) => (
                        <li key={i}>{desc}</li>
                      ))}
                    </ul>
                  ) : index == 4 ? (
                    <>
                      <ul>
                        <div>{service.description[0]}</div>
                        {service.description.slice(1).map((desc, i) => (
                          <li key={i + 1}>{desc}</li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      {service.description.map((desc, i) => (
                        <div key={i}>{desc}</div>
                      ))}
                    </>
                  )}
                </Description>
              </ServiceCard>
            </StyledItems>
          ))}
        </FAQList>
      </FaqB>
    </FAQTop>
  );
}

export default FreuentlyAskedQuestions;