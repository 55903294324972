import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useFormik } from "formik";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { useSearchParams } from "react-router-dom";
import { BaseURL } from "../../variables";
import GrantCalculator from "./calculator";
import {
  StyledDiv,
  StyledHeading,
  StyledBgDiv,
  StyledLabel,
  StyledInput,
  StyledRadio,
  StyledTextArea,
  StyledBgDivOne,
  StyledButton,
  SquareRadioButton,
  StyledTerms,
  DoubleOutlinedRadioButton,
  StyledImage,
  StyledConditons,
  StyledSpan,
  StyledReviewBox,
  StyledReviewButton,
  StyledLabelButtonDiv,
  StyledRadioDiv,
  StyledRadioLabelDiv,
  StyledRadioDiv2,
} from "./style";
import "./index.css";
import { FormikSchema } from "./formSchema";
import { InputCss } from "./input";
import { device } from "../../devices";

export default function ApplicationForm() {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const isDesktopDevice = window.innerWidth <= 1280;
  const isPCDevice = window.innerWidth >= 1536;
  const isTabletLargeDevice = window.innerWidth <= 1200;
  const isTabletDevice = window.innerWidth <= 1023;
  const isMobileLargeDevice = window.innerWidth <= 767;
  const isMobileDevice = window.innerWidth <= 480;
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const [appList, setAppList] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [formInitialValues, setFormInitialValues] = useState({
    organization_name: "",
    phone_number: "",
    email: "",
    role: "",
    contact_person: "",
    address: "",
    organization_type: "",
    organization_details: "",
    service_grant_type: "",
    project_details: "",
    budget: "",
    conditions: "",
  });
  const [AdminRev, setAdminRev] = useState("");
  const [isAdmin] = useState(id ? true : false);
  const navigate = useNavigate();
  // update user status (Admin only)
  const handleUpdateStatus = (e, status) => {
    e.preventDefault();

    axios
      .post(
        `${BaseURL}grantApplication/update/${id}`,
        {
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);

        navigate("/admin/dashboard");
      })
      .catch((error) => {
        console.error("Failed to update:", error.message);
        // Handle login failure, show error message, etc.
      });
  };
  // admin review
  const handleUpdateReview = (e) => {
    console.log(AdminRev);

    e.preventDefault();

    axios
      .post(
        `${BaseURL}grantApplication/updateRemarks/${id}`,
        {
          liamcrest_remarks: AdminRev,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);

        navigate("/admin/dashboard");
      })
      .catch((error) => {
        console.error("Failed to update:", error.message);
        // Handle login failure, show error message, etc.
      });
  };

  const handleLoadDate = (formik) => {
    setFormInitialValues(null);
    axios
      .get(`${BaseURL}grantApplication/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        formik.setValues({
          ...response.data.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      });
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    navigate("/");
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: FormikSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      console.log("done");
      submitForm(values);
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const submitForm = (formData) => {
    const API_URL = `${BaseURL}grantApplication/store`;

    axios
      .post(API_URL, {
        email: formData.email,
        role: formData.role,
        contact_person: formData.contact_person,
        organization_name: formData.organization_name,
        phone_number: formData.phone_number,
        organization_type: formData.organization_type,
        organization_details: formData.organization_details,
        address: formData.address,
        service_grant_type: formData.service_grant_type,
        project_details: formData.project_details,
        budget: formData.budget,
      })
      .then((response) => {
        console.log("API Response:", response.data);
        if (response.data.status === "success") {
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };
  const handleCancel = () => {
    formik.resetForm();
  };

  useEffect(() => {
    if (id) handleLoadDate(formik);
    console.log(formik.values);
  }, []);

  return (
    <div className="mb-5">
      <form onSubmit={formik.handleSubmit} ref={componentRef}>
        <Row className="g-0">
          <Col lg={12} md={12} xs={12} className="ps-5 pt-4 pb-3 p_logo">
            <a href="https://grant.liamcrest.com/">
              <img
                src="/FormImages/liamCrest.svg"
                alt=""
                className={isPCDevice ? "ps-5" : ""}
              />
            </a>
          </Col>
          <Col lg={12}>
            <StyledDiv className="d-flex flex-row justify-content-center align-items-center">
              {isAdmin ? (
                <StyledHeading className="">
                  Liam Crest <span>Grant</span> Application
                </StyledHeading>
              ) : (
                <StyledHeading className="">
                  Liam Crest <span>Grant</span> Application:{" "}
                  <span>Closed Now</span>
                </StyledHeading>
              )}
            </StyledDiv>
          </Col>
 {isAdmin ? ( <Col lg={12}>
            <StyledBgDiv>
              <Row className="g-0">
                {isMobileLargeDevice || isMobileDevice ? (
                  <>
                    <Col className=" mt-3 d-flex flex-row justify-content-center">
                      <div className=" d-flex flex-column">
                        <div className="ms-2 mb-3">
                          <StyledImage
                            src={
                              isMobileDevice
                                ? "/FormImages/mOrgName.png"
                                : "/FormImages/orgName.png"
                            }
                            alt=""
                          />
                          <StyledLabel className="ms-3">
                            Organization Name*
                          </StyledLabel>
                        </div>
                        <StyledInput
                          type="text"
                          name="organization_name"
                          onChange={formik.handleChange}
                          value={formik.values.organization_name}
                          css={
                            isMobileDevice
                              ? InputCss.mobileWidth
                              : InputCss.mobileLargeWidth
                          }
                          readOnly={isAdmin}
                        />
                        <span className="form-label text-danger mt-2">
                          {formik.errors.organization_name}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      className="mt-3  d-flex flex-row justify-content-center"
                    >
                      <div className="d-flex flex-column">
                        <div className=" ms-2 mb-3">
                          <StyledImage
                            src={
                              isMobileDevice
                                ? "/FormImages/mPhone.png"
                                : "/FormImages/phone.png"
                            }
                            alt=""
                          />
                          <StyledLabel className="ms-3">
                            Phone Number*
                          </StyledLabel>
                        </div>
                        <StyledInput
                          type="tel"
                          name="phone_number"
                          onChange={formik.handleChange}
                          value={formik.values.phone_number}
                          css={
                            isMobileDevice
                              ? InputCss.mobileWidth
                              : InputCss.mobileLargeWidth
                          }
                          readOnly={isAdmin}
                        />
                        <span className="form-label text-danger mt-2">
                          {formik.errors.phone_number}
                        </span>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col
                    lg={12}
                    className="mt-5  d-flex flex-row justify-content-center"
                  >
                    <div className=" me-4 d-flex flex-column">
                      <div className="ms-2 mb-3">
                        <StyledImage src="/FormImages/orgName.png" alt="" />
                        <StyledLabel className="ms-3">
                          Organization Name*
                        </StyledLabel>
                      </div>
                      <StyledInput
                        type="text"
                        name="organization_name"
                        onChange={formik.handleChange}
                        value={formik.values.organization_name}
                        css={
                          isTabletDevice
                            ? InputCss.tabletHalfWidth
                            : isTabletLargeDevice
                            ? InputCss.tabletLargeHalfWidth
                            : isDesktopDevice
                            ? InputCss.desktopHalfWidth
                            : InputCss.halfWidth
                        }
                        readOnly={isAdmin}
                        className="p_zero"
                      />
                      <span className="form-label text-danger mt-2">
                        {formik.errors.organization_name}
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <div className=" ms-2 mb-3">
                        <StyledImage src="/FormImages/phone.png" alt="" />
                        <StyledLabel className="ms-3">
                          Phone Number*
                        </StyledLabel>
                      </div>
                      <StyledInput
                        type="tel"
                        name="phone_number"
                        onChange={formik.handleChange}
                        value={formik.values.phone_number}
                        css={
                          isTabletDevice
                            ? InputCss.tabletHalfWidth
                            : isTabletLargeDevice
                            ? InputCss.tabletLargeHalfWidth
                            : isDesktopDevice
                            ? InputCss.desktopHalfWidth
                            : InputCss.halfWidth
                        }
                        readOnly={isAdmin}
                        className="p_zero"
                      />
                      <span className="form-label text-danger mt-2">
                        {formik.errors.phone_number}
                      </span>
                    </div>
                  </Col>
                )}

                {isMobileLargeDevice || isMobileDevice ? (
                  <>
                    <Col
                      sm={12}
                      className=" mt-3  d-flex flex-row justify-content-center"
                    >
                      <div className="d-flex flex-column">
                        <div className="ms-2 mb-3">
                          <StyledImage
                            src={
                              isMobileDevice
                                ? "/FormImages/mEmail.png"
                                : "/FormImages/email.png"
                            }
                            alt=""
                          />
                          <StyledLabel className="ms-3">
                            Email Address*
                          </StyledLabel>
                        </div>
                        <StyledInput
                          type="email"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          css={
                            isMobileDevice
                              ? InputCss.mobileWidth
                              : InputCss.mobileLargeWidth
                          }
                          readOnly={isAdmin}
                        />
                        <span className="form-label text-danger mt-2">
                          {formik.errors.email}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      className="mt-3  d-flex flex-row justify-content-center"
                    >
                      <div className="d-flex flex-column">
                        <div className=" ms-2 mb-3 d-flex flex-row">
                          <div>
                            <StyledImage
                              src={
                                isMobileDevice
                                  ? "/FormImages/mRole.png"
                                  : "/FormImages/role.png"
                              }
                              alt=""
                            />
                          </div>
                          <StyledLabel className="ms-3">Role*</StyledLabel>
                        </div>
                        <StyledInput
                          type="text"
                          name="role"
                          onChange={formik.handleChange}
                          value={formik.values.role}
                          css={
                            isMobileDevice
                              ? InputCss.mobileWidth
                              : InputCss.mobileLargeWidth
                          }
                          readOnly={isAdmin}
                        />
                        <span className="form-label text-danger mt-2">
                          {formik.errors.role}
                        </span>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col
                    lg={12}
                    className="pt-5 d-flex flex-row justify-content-center"
                  >
                    <div className="me-4 d-flex flex-column">
                      <div className="ms-2 mb-3 d-flex flex-row">
                        <div>
                          <StyledImage src="/FormImages/email.png" alt="" />
                        </div>
                        <StyledLabel className="ms-3">
                          Email Address*
                        </StyledLabel>
                      </div>
                      <StyledInput
                        type="email"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        css={
                          isTabletDevice
                            ? InputCss.tabletHalfWidth
                            : isTabletLargeDevice
                            ? InputCss.tabletLargeHalfWidth
                            : isDesktopDevice
                            ? InputCss.desktopHalfWidth
                            : InputCss.halfWidth
                        }
                        readOnly={isAdmin}
                        className="p_first"
                      />
                      <span className="form-label text-danger mt-2">
                        {formik.errors.email}
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <div className=" ms-2 mb-3 d-flex flex-row">
                        <div
                          style={{
                            marginTop: isTabletDevice
                              ? "-10%"
                              : isTabletLargeDevice
                              ? "-6.5%"
                              : isDesktopDevice
                              ? "-5.5%"
                              : "-5%",
                          }}
                        >
                          <StyledImage src="/FormImages/role.png" alt="" />
                        </div>
                        <StyledLabel className="ms-3">Role*</StyledLabel>
                      </div>
                      <StyledInput
                        type="text"
                        name="role"
                        onChange={formik.handleChange}
                        value={formik.values.role}
                        css={
                          isTabletDevice
                            ? InputCss.tabletHalfWidth
                            : isTabletLargeDevice
                            ? InputCss.tabletLargeHalfWidth
                            : isDesktopDevice
                            ? InputCss.desktopHalfWidth
                            : InputCss.halfWidth
                        }
                        readOnly={isAdmin}
                        className="p_first"
                      />
                      <span className="form-label text-danger mt-2">
                        {formik.errors.role}
                      </span>
                    </div>
                  </Col>
                )}

                <Col
                  lg={12}
                  className={
                    isMobileLargeDevice ? "pt-3 p_row3" : "pt-5 p_row3"
                  }
                >
                  <div
                    className="mb-3 p_label"
                    // style={{paddingLeft: "7.5%"}}
                    style={{
                      paddingLeft: isMobileDevice
                        ? "14%"
                        : isDesktopDevice
                        ? "5%"
                        : "7.5%",
                    }}
                  >
                    <StyledImage
                      src={
                        isMobileDevice
                          ? "/FormImages/mContactPerson.png"
                          : "/FormImages/contactPerson.png"
                      }
                      alt=""
                    />
                    <StyledLabel className="ms-3">Contact Person*</StyledLabel>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <StyledInput
                      type="text"
                      name="contact_person"
                      onChange={formik.handleChange}
                      value={formik.values.contact_person}
                      css={
                        isMobileDevice
                          ? InputCss.mobileWidth
                          : isMobileLargeDevice
                          ? InputCss.mobileLargeWidth
                          : isTabletDevice
                          ? InputCss.tabletFullWidth
                          : isTabletLargeDevice
                          ? InputCss.tabletLargeFullWidth
                          : isDesktopDevice
                          ? InputCss.desktopFullWidht
                          : InputCss.fullWidht
                      }
                      readOnly={isAdmin}
                    />
                  </div>
                </Col>
                <span
                  className="form-label text-danger mt-2"
                  style={{
                    paddingLeft: isMobileDevice
                      ? "14%"
                      : isDesktopDevice
                      ? "5%"
                      : "7.3%",
                  }}
                >
                  {formik.errors.contact_person}
                </span>

                <Col
                  lg={12}
                  className={
                    isMobileLargeDevice ? "pt-3 p_row3" : "pt-5 p_row4"
                  }
                >
                  <div
                    className="mb-3 p_label"
                    style={{
                      paddingLeft: isMobileDevice
                        ? "14%"
                        : isDesktopDevice
                        ? "5.5%"
                        : "7.8%",
                    }}
                  >
                    <StyledImage
                      src={
                        isMobileDevice
                          ? "/FormImages/mAddress.png"
                          : "/FormImages/address.png"
                      }
                      alt=""
                    />
                    <StyledLabel className="ms-3">Address*</StyledLabel>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <StyledInput
                      type="text"
                      name="address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                      css={
                        isMobileDevice
                          ? InputCss.mobileWidth
                          : isMobileLargeDevice
                          ? InputCss.mobileLargeWidth
                          : isTabletDevice
                          ? InputCss.tabletFullWidth
                          : isTabletLargeDevice
                          ? InputCss.tabletLargeFullWidth
                          : isDesktopDevice
                          ? InputCss.desktopFullWidht
                          : InputCss.fullWidht
                      }
                      readOnly={isAdmin}
                    />
                  </div>
                </Col>
                <span
                  className="form-label text-danger mt-2"
                  style={{
                    paddingLeft: isMobileDevice
                      ? "14%"
                      : isDesktopDevice
                      ? "5%"
                      : "7.3%",
                  }}
                >
                  {formik.errors.address}
                </span>

                <Col lg={12} className="pt-5 p_row5">
                  <div
                    className="mb-3 d-flex flex-row"
                    style={{
                      paddingLeft: isMobileDevice
                        ? "14%"
                        : isMobileDevice
                        ? "8%"
                        : isDesktopDevice
                        ? "5.5%"
                        : "7.8%",
                    }}
                  >
                    <div
                      style={{ marginTop: isMobileDevice ? "-0.3%" : "-0.4%" }}
                    >
                      <StyledImage
                        src={
                          isMobileDevice
                            ? "/FormImages/mOrgTypes.png"
                            : "/FormImages/orgType.png"
                        }
                        alt=""
                      />
                    </div>
                    <StyledLabel
                      className="ms-3"
                      style={{
                        width: isMobileDevice
                          ? "160px"
                          : isMobileLargeDevice
                          ? "500px"
                          : isMobileDevice
                          ? "100px"
                          : "",
                      }}
                    >
                      Organization Types: (Please select the organizational
                      type)*
                    </StyledLabel>
                  </div>

                  <StyledRadioDiv className=" mt-5 ">
                    {isMobileDevice ? (
                      <>
                        <Col>
                          <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="State and Federal Agencies"
                              checked={
                                formik.values.organization_type ===
                                "State and Federal Agencies"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel
                              style={{
                                width: "140px",
                                fontSize: "12px",
                              }}
                            >
                              State and Federal Agencies
                            </StyledLabel>
                          </StyledRadio>
                          <StyledRadio className="mt-3 d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="Schools, Colleges, and Universities"
                              checked={
                                formik.values.organization_type ===
                                "Schools, Colleges, and Universities"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel
                              style={{ width: "140px", fontSize: "12px" }}
                            >
                              Schools, Colleges, Universities
                            </StyledLabel>
                          </StyledRadio>
                          <StyledRadio className="mt-3 d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="Non-profit Agencies"
                              checked={
                                formik.values.organization_type ===
                                "Non-profit Agencies"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel
                              style={{ width: "140px", fontSize: "12px" }}
                            >
                              Non-profit Agencies
                            </StyledLabel>
                          </StyledRadio>

                          <StyledRadio className="mt-3 d-flex flex-row justify-content-center align-items-center ">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="Municipalities and Government"
                              checked={
                                formik.values.organization_type ===
                                "Municipalities and Government"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel
                              style={{ width: "140px", fontSize: "12px" }}
                            >
                              Municipalities and Government
                            </StyledLabel>
                          </StyledRadio>

                          <StyledRadio className="mt-3 d-flex flex-row justify-content-center align-items-center ">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="Small Businesss"
                              checked={
                                formik.values.organization_type ===
                                "Small Business"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel
                              style={{ width: "140px", fontSize: "12px" }}
                            >
                              Small Business
                            </StyledLabel>
                          </StyledRadio>
                        </Col>
                      </>
                    ) : isTabletDevice || isMobileLargeDevice ? (
                      <>
                        <Col className="">
                          <div className="d-flex flex-row justify-content-between ">
                            <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                              <DoubleOutlinedRadioButton
                                type="radio"
                                name="organization_type"
                                value="State and Federal Agencies"
                                checked={
                                  formik.values.organization_type ===
                                  "State and Federal Agencies"
                                }
                                onChange={formik.handleChange}
                                className="me-3"
                                disabled={isAdmin}
                              />
                              <StyledLabel
                                style={{
                                  width: "190px",
                                  fontSize: "15px",
                                }}
                              >
                                State and Federal Agencies
                              </StyledLabel>
                            </StyledRadio>
                            <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                              <DoubleOutlinedRadioButton
                                type="radio"
                                name="organization_type"
                                value="Schools, Colleges, and Universities"
                                checked={
                                  formik.values.organization_type ===
                                  "Schools, Colleges, and Universities"
                                }
                                onChange={formik.handleChange}
                                className="me-4"
                                disabled={isAdmin}
                              />
                              <StyledLabel
                                style={{ width: "190px", fontSize: "15px" }}
                              >
                                Schools, Colleges, Universities
                              </StyledLabel>
                            </StyledRadio>
                          </div>
                          <div className="mt-4 d-flex flex-row justify-content-between">
                            <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                              <DoubleOutlinedRadioButton
                                type="radio"
                                name="organization_type"
                                value="Non-profit Agencies"
                                checked={
                                  formik.values.organization_type ===
                                  "Non-profit Agencies"
                                }
                                onChange={formik.handleChange}
                                className="me-4"
                                disabled={isAdmin}
                              />
                              <StyledLabel
                                style={{ width: "190px", fontSize: "15px" }}
                              >
                                Non-profit Agencies
                              </StyledLabel>
                            </StyledRadio>
                            <StyledRadio className="d-flex flex-row justify-content-center align-items-center ">
                              <DoubleOutlinedRadioButton
                                type="radio"
                                name="organization_type"
                                value="Municipalities and Government"
                                checked={
                                  formik.values.organization_type ===
                                  "Municipalities and Government"
                                }
                                onChange={formik.handleChange}
                                className="me-4"
                                disabled={isAdmin}
                              />
                              <StyledLabel
                                style={{ width: "190px", fontSize: "15px" }}
                              >
                                Municipalities and Government
                              </StyledLabel>
                            </StyledRadio>
                          </div>
                          <div className="mt-4 d-flex flex-row justify-content-between">
                            <StyledRadio className="d-flex flex-row justify-content-center align-items-center ">
                              <DoubleOutlinedRadioButton
                                type="radio"
                                name="organization_type"
                                value="Small Business"
                                checked={
                                  formik.values.organization_type ===
                                  "Small Business"
                                }
                                onChange={formik.handleChange}
                                className="me-4"
                                disabled={isAdmin}
                              />
                              <StyledLabel
                                style={{ width: "190px", fontSize: "15px" }}
                              >
                                Small Business
                              </StyledLabel>
                            </StyledRadio>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col
                          lg={12}
                          className="d-flex flex-row justify-content-between"
                        >
                          <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="State and Federal Agencies"
                              checked={
                                formik.values.organization_type ===
                                "State and Federal Agencies"
                              }
                              onChange={formik.handleChange}
                              className="me-3"
                              disabled={isAdmin}
                            />
                            <StyledLabel style={{ width: "204px" }}>
                              State and Federal Agencies
                            </StyledLabel>
                          </StyledRadio>
                          <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="Schools, Colleges, and Universities"
                              checked={
                                formik.values.organization_type ===
                                "Schools, Colleges, and Universities"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel style={{ width: "204px" }}>
                              Schools, Colleges, Universities
                            </StyledLabel>
                          </StyledRadio>

                          <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="Non-profit Agencies"
                              checked={
                                formik.values.organization_type ===
                                "Non-profit Agencies"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel style={{ width: "204px" }}>
                              Non-profit Agencies
                            </StyledLabel>
                          </StyledRadio>
                        </Col>

                        <Col lg={12} className="mt-4 d-flex flex-row ">
                          <StyledRadio className="me-4 d-flex flex-row justify-content-center align-items-center ">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="Municipalities and Government"
                              checked={
                                formik.values.organization_type ===
                                "Municipalities and Government"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel style={{ width: "204px" }}>
                              Municipalities and Government
                            </StyledLabel>
                          </StyledRadio>
                          <StyledRadio className="me-4 d-flex flex-row justify-content-center align-items-center ">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="organization_type"
                              value="Small Business"
                              checked={
                                formik.values.organization_type ===
                                "Small Business"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel style={{ width: "204px" }}>
                              Small Business
                            </StyledLabel>
                          </StyledRadio>
                        </Col>
                      </>
                    )}
                  </StyledRadioDiv>
                </Col>
                <span
                  className="form-label text-danger mt-3"
                  style={{
                    paddingLeft: isMobileDevice
                      ? "14%"
                      : isDesktopDevice
                      ? "10%"
                      : "12%",
                  }}
                >
                  {formik.errors.organization_type}
                </span>

                <Col lg={12} className="pt-5 p_row6">
                  <div
                    className="mb-3 d-flex flex-row p_label6"
                    style={{
                      paddingLeft: isMobileDevice
                        ? "14%"
                        : isDesktopDevice
                        ? "5.5%"
                        : "7.8%",
                    }}
                  >
                    <div>
                      <StyledImage
                        src={
                          isMobileDevice
                            ? "/FormImages/mOrgDetails.png"
                            : "/FormImages/orgDetails.png"
                        }
                        alt=""
                      />
                    </div>
                    <StyledLabel
                      className="ms-3"
                      style={{ width: isMobileDevice ? "220px" : "" }}
                    >
                      Tell us a little more about your organization*
                    </StyledLabel>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <StyledTextArea
                      className="p_input6"
                      name="organization_details"
                      onChange={formik.handleChange}
                      value={formik.values.organization_details}
                      readOnly={isAdmin}
                    />
                  </div>
                </Col>
                <span
                  className="form-label text-danger mt-2"
                  style={{
                    paddingLeft: isMobileDevice
                      ? "14%"
                      : isDesktopDevice
                      ? "5%"
                      : "7.3%",
                  }}
                >
                  {formik.errors.organization_details}
                </span>
              </Row>
            </StyledBgDiv>
            <StyledBgDivOne>
              <Row className="g-0">
                <Col lg={12} className="pt-5">
                  <StyledRadioLabelDiv className="d-flex flex-row mb-3 ">
                    <div
                      className="d-flex flex-row "
                      style={{ marginTop: isMobileDevice ? "-0.5%" : "-1%" }}
                    >
                      <div>
                        <StyledImage
                          src={
                            isMobileDevice
                              ? "/FormImages/mServiceGrant.png"
                              : "/FormImages/serviceType.png"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <StyledLabel
                      className="ms-3"
                      style={{ width: isMobileDevice ? "180px" : "" }}
                    >
                      Which type of service grant are you applying for*
                    </StyledLabel>
                  </StyledRadioLabelDiv>

                  <StyledRadioDiv2 className="mt-5">
                    {isMobileDevice ? (
                      <>
                        <Col>
                          <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="service_grant_type"
                              value="Online Training Development Services"
                              checked={
                                formik.values.service_grant_type ===
                                "Online Training Development Services"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel
                              style={{
                                width: "140px",
                                fontSize: "12px",
                              }}
                            >
                              Online Training Development Services
                            </StyledLabel>
                          </StyledRadio>
                          <StyledRadio className="mt-3 d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="service_grant_type"
                              value="Software Development Services"
                              checked={
                                formik.values.service_grant_type ===
                                "Software Development Services"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel
                              style={{
                                width: "140px",
                                fontSize: "12px",
                              }}
                            >
                              Software Development Services
                            </StyledLabel>
                          </StyledRadio>
                          <StyledRadio className="mt-3 d-flex flex-row justify-content-center align-items-center">
                            <DoubleOutlinedRadioButton
                              type="radio"
                              name="service_grant_type"
                              value="Website Development Services"
                              checked={
                                formik.values.service_grant_type ===
                                "Website Development Services"
                              }
                              onChange={formik.handleChange}
                              className="me-4"
                              disabled={isAdmin}
                            />
                            <StyledLabel
                              style={{
                                width: "140px",
                                fontSize: "12px",
                              }}
                            >
                              Website Development Services
                            </StyledLabel>
                          </StyledRadio>
                        </Col>
                      </>
                    ) : isTabletDevice || isMobileLargeDevice ? (
                      <>
                        <Col>
                          <div className="mb-4 d-flex flex-row justify-content-between">
                            <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                              <DoubleOutlinedRadioButton
                                type="radio"
                                name="service_grant_type"
                                value="Online Training Development Services"
                                checked={
                                  formik.values.service_grant_type ===
                                  "Online Training Development Services"
                                }
                                onChange={formik.handleChange}
                                className="me-4"
                                disabled={isAdmin}
                              />
                              <StyledLabel
                                style={{
                                  width: isMobileLargeDevice
                                    ? "200px"
                                    : "210px",
                                  fontSize: "15px",
                                }}
                              >
                                Online Training Development Services
                              </StyledLabel>
                            </StyledRadio>

                            <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                              <DoubleOutlinedRadioButton
                                type="radio"
                                name="service_grant_type"
                                value="Software Development Services"
                                checked={
                                  formik.values.service_grant_type ===
                                  "Software Development Services"
                                }
                                onChange={formik.handleChange}
                                className="me-4"
                                disabled={isAdmin}
                              />
                              <StyledLabel
                                style={{
                                  width: isMobileLargeDevice
                                    ? "200px"
                                    : "210px",
                                  fontSize: "15px",
                                }}
                              >
                                Software Development Services
                              </StyledLabel>
                            </StyledRadio>
                          </div>
                          <div className="d-flex flex-row justify-content-between">
                            <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                              <DoubleOutlinedRadioButton
                                type="radio"
                                name="service_grant_type"
                                value="Website Development Services"
                                checked={
                                  formik.values.service_grant_type ===
                                  "Website Development Services"
                                }
                                onChange={formik.handleChange}
                                className="me-4"
                                disabled={isAdmin}
                              />
                              <StyledLabel
                                style={{
                                  width: isMobileLargeDevice
                                    ? "200px"
                                    : "210px",
                                  fontSize: "15px",
                                }}
                              >
                                Website Development Services
                              </StyledLabel>
                            </StyledRadio>
                          </div>
                        </Col>
                      </>
                    ) : (
                      <Col
                        lg={12}
                        className="d-flex flex-row justify-content-between"
                      >
                        <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                          <DoubleOutlinedRadioButton
                            type="radio"
                            name="service_grant_type"
                            value="Online Training Development Services"
                            checked={
                              formik.values.service_grant_type ===
                              "Online Training Development Services"
                            }
                            onChange={formik.handleChange}
                            className="me-4"
                            disabled={isAdmin}
                          />
                          <StyledLabel
                            style={{
                              width: isTabletLargeDevice ? "200px" : "231px",
                            }}
                          >
                            Online Training Development Services
                          </StyledLabel>
                        </StyledRadio>

                        <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                          <DoubleOutlinedRadioButton
                            type="radio"
                            name="service_grant_type"
                            value="Software Development Services"
                            checked={
                              formik.values.service_grant_type ===
                              "Software Development Services"
                            }
                            onChange={formik.handleChange}
                            className="me-4"
                            disabled={isAdmin}
                          />
                          <StyledLabel
                            style={{
                              width: isTabletLargeDevice ? "200px" : "231px",
                            }}
                          >
                            Software Development Services
                          </StyledLabel>
                        </StyledRadio>
                        <StyledRadio className="d-flex flex-row justify-content-center align-items-center">
                          <DoubleOutlinedRadioButton
                            type="radio"
                            name="service_grant_type"
                            value="Website Development Services"
                            checked={
                              formik.values.service_grant_type ===
                              "Website Development Services"
                            }
                            onChange={formik.handleChange}
                            className="me-4"
                            disabled={isAdmin}
                          />
                          <StyledLabel
                            style={{
                              width: isTabletLargeDevice ? "200px" : "231px",
                            }}
                          >
                            Website Development Services
                          </StyledLabel>
                        </StyledRadio>
                      </Col>
                    )}
                  </StyledRadioDiv2>
                </Col>

                <span
                  className="form-label text-danger mt-2"
                  style={{
                    paddingLeft: isMobileDevice
                      ? "14%"
                      : isDesktopDevice
                      ? "10%"
                      : "12%",
                  }}
                >
                  {formik.errors.service_grant_type}
                </span>

                <Col lg={12} className="pt-5 p_row7">
                  <div
                    className="mb-3 d-flex flex-row p_label7"
                    style={{
                      paddingLeft: isMobileDevice
                        ? "14%"
                        : isDesktopDevice
                        ? "5.5%"
                        : "7.8%",
                    }}
                  >
                    <div style={{ marginTop: isMobileDevice ? "" : "-1%" }}>
                      <StyledImage
                        src={
                          isMobileDevice
                            ? "/FormImages/mProjectDetails.png"
                            : "/FormImages/projectDetails.png"
                        }
                        alt=""
                      />
                    </div>
                    <StyledLabel
                      className="ms-3"
                      style={{ width: isMobileDevice ? "220px" : "" }}
                    >
                      Tell us a little more about your project*
                    </StyledLabel>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <StyledTextArea
                      className="p_input7"
                      name="project_details"
                      onChange={formik.handleChange}
                      value={formik.values.project_details}
                      readOnly={isAdmin}
                    />
                  </div>
                </Col>
                <span
                  className="form-label text-danger mt-2"
                  style={{
                    paddingLeft: isMobileDevice
                      ? "14%"
                      : isDesktopDevice
                      ? "5%"
                      : "7.3%",
                  }}
                >
                  {formik.errors.project_details}
                </span>

                {!isAdmin ? (
                  <Col className="pt-5 pb-4 p_hide">
                    <div
                      className="mb-3 d-flex flex-row"
                      style={{
                        paddingLeft: isMobileDevice
                          ? "14%"
                          : isDesktopDevice
                          ? "5.5%"
                          : "7.6%",
                      }}
                    >
                      <div>
                        <StyledImage src="/FormImages/calculator.png" alt="" />
                      </div>

                      <StyledLabel
                        className="ms-3"
                        style={{ width: isMobileDevice ? "200px" : "" }}
                      >
                        Calculate your grant amount*
                        <br />
                        <div>
                          <span style={{ fontSize: "16px" }}>
                            (Calculate the approximate cost of your project )
                          </span>
                        </div>
                      </StyledLabel>
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <GrantCalculator />
                    </div>
                  </Col>
                ) : (
                  <></>
                )}

                <Col lg={12} className="pt-5 p_row8">
                  <StyledConditons className="mb-5">
                    Our grant program is structured to cover <b>part</b> of the
                    costs of the services requested below. This community
                    service grant is <b>not 100% grant-funded;</b> rather, grant
                    services are offered on a <b>sliding scale</b> to
                    organizations in need. We assess grant amounts based on the
                    impact of the organization and the projected benefits to the
                    community.
                    <br />
                    <br /> Please be assured that there are{" "}
                    <b>
                      no caps or restrictions on the amount of contribution
                    </b>{" "}
                    from organizations applying for the grant. At Liam Crest, we
                    believe in evaluating grant applications based solely on{" "}
                    <b>the merit of the cause and service being proposed.</b>
                    Our grant program operates on the principle{" "}
                    <b>
                      that organizations should request only what they need to
                      fulfill their projects effectively.
                    </b>{" "}
                    By eliminating contribution caps, we aim to create an equal
                    opportunity for all applicants to access the support they
                    require to make a meaningful impact in their communities
                  </StyledConditons>

                  <div
                    className="mb-3 d-flex flex-row p_label8"
                    style={{
                      paddingLeft: isMobileDevice
                        ? "14%"
                        : isDesktopDevice
                        ? "5.5%"
                        : "7.8%",
                    }}
                  >
                    <div>
                      <StyledImage
                        src={
                          isMobileDevice
                            ? "/FormImages/mBudget.png"
                            : "/FormImages/orgBudget.png"
                        }
                        alt=""
                      />
                    </div>

                    <StyledLabel
                      className="ms-3"
                      style={{ width: isMobileDevice ? "200px" : "" }}
                    >
                      Organization's Budget for this Project*
                      <br />
                      <div style={{ marginTop: "4px" }}>
                        <span style={{ fontSize: "16px" }}>
                          (Use Our Grant Calculator Above to Help Determine Size
                          of the Service Grant Requested)*
                        </span>
                      </div>
                    </StyledLabel>
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <StyledTextArea
                      className="p_input8"
                      name="budget"
                      onChange={formik.handleChange}
                      value={formik.values.budget}
                      readOnly={isAdmin}
                    />
                  </div>
                </Col>
                <span
                  className="form-label text-danger mt-2"
                  style={{
                    paddingLeft: isMobileDevice
                      ? "14%"
                      : isDesktopDevice
                      ? "5%"
                      : "7.3%",
                  }}
                >
                  {formik.errors.budget}
                </span>

                {isAdmin ? (
                  <Col
                    lg={12}
                    className={
                      isTabletDevice
                        ? "pt-5 d-flex flex-column"
                        : "pt-5 d-flex flex-column "
                    }
                  >
                    <div
                      className="mb-3 d-flex flex-row"
                      style={{
                        paddingLeft: isMobileDevice
                          ? "14%"
                          : isDesktopDevice
                          ? "5.5%"
                          : "7.8%",
                      }}
                    >
                      <div>
                        <StyledImage
                          src={
                            isMobileDevice
                              ? "/FormImages/mTerms.png"
                              : "/FormImages/termsNcondition.png"
                          }
                          alt=""
                        />
                      </div>
                      <StyledLabelButtonDiv className=" d-flex flex-row justify-content-between">
                        <StyledLabel
                          className="ms-3"
                          style={{
                            width: isMobileDevice
                              ? "200px"
                              : isMobileLargeDevice
                              ? "500px"
                              : "",
                          }}
                        >
                          Reviews
                        </StyledLabel>
                        <StyledReviewButton
                          className="ms-5"
                          onClick={(e) => handleUpdateReview(e)}
                        >
                          Save Review
                        </StyledReviewButton>
                      </StyledLabelButtonDiv>
                    </div>

                    <StyledReviewBox
                      name="adminReviews"
                      value={AdminRev}
                      onChange={(e) => {
                        setAdminRev(e.target.value);
                      }}
                    />
                  </Col>
                ) : (
                  <Col
                    lg={12}
                    className={
                      isMobileDevice
                        ? "pt-5 d-flex flex-column "
                        : "pt-5 d-flex flex-row "
                    }
                  >
                    <div
                      className="mb-3 d-flex flex-row"
                      style={{ paddingLeft: "7.8%" }}
                    >
                      <div>
                        <StyledImage
                          src={
                            isMobileDevice
                              ? "/FormImages/mTerms.png"
                              : "/FormImages/termsNcondition.png"
                          }
                          alt=""
                        />
                      </div>

                      <StyledLabel className="ms-3">
                        Terms and Conditions:
                        <br />
                        <div style={{ marginTop: "6px" }}>
                          <span className="custom-span">
                            I submit that all the information above is accurate
                            to the best of my ability.
                          </span>
                        </div>
                      </StyledLabel>
                    </div>
                    <div
                      className={
                        isMobileDevice
                          ? " d-flex flex-row justify-content-center"
                          : "ms-5 d-flex flex-row"
                      }
                    >
                      <SquareRadioButton
                        type="radio"
                        name="conditions"
                        value="Yes"
                        onChange={formik.handleChange}
                        checked={formik.values.conditions === "Yes"}
                      />
                      <StyledTerms className="me-4 mt-2">Yes</StyledTerms>
                      <SquareRadioButton
                        type="radio"
                        name="conditions"
                        value="No"
                        onChange={formik.handleChange}
                        checked={formik.values.conditions === "No"}
                      />
                      <StyledTerms className="mt-2">No</StyledTerms>
                    </div>
                    <span
                      className="form-label text-danger mt-2"
                      style={{
                        paddingLeft: isMobileDevice
                          ? "14%"
                          : isTabletDevice
                          ? "5.5%"
                          : "2%",
                      }}
                    >
                      {formik.errors.conditions}
                    </span>
                  </Col>
                )}

                <Col
                  className=" d-print-none pt-5 d-flex flex-row justify-content-center"
                  style={{ marginTop: "10%" }}
                >
                  {isAdmin ? (
                    <div
                      className={
                        isMobileDevice
                          ? "d-flex flex-column align-items-center "
                          : "d-flex flex-row "
                      }
                    >
                      <StyledButton
                        type="button"
                        className={isMobileDevice ? "mb-3 " : "me-4 "}
                        onClick={(e) => handleUpdateStatus(e, "Approved")}
                      >
                        APPROVE
                      </StyledButton>
                      <StyledButton
                        type="button"
                        className={isMobileDevice ? "mb-3" : "me-4"}
                        onClick={(e) => handleUpdateStatus(e, "Denied")}
                      >
                        DENY
                      </StyledButton>
                      <StyledButton
                        type="button"
                        onClick={(e) => handleUpdateStatus(e, "Incomplete")}
                      >
                        INCOMPLETE
                      </StyledButton>
                    </div>
                  ) : (
                    <>
                      <div
                        className={
                          isMobileDevice
                            ? "d-flex flex-column align-items-center "
                            : "d-flex flex-row "
                        }
                      >
                        <StyledButton
                          type="button"
                          className={isMobileDevice ? "mb-3 " : "me-4 "}
                          onClick={() => navigate("/")}
                        >
                          BACK TO HOME
                        </StyledButton>
                        <StyledButton
                          className={isMobileDevice ? "mb-3" : "me-4"}
                          type="submit"
                          onClick={() => {
                            console.log("clicked");
                          }}
                        >
                          SUBMIT
                        </StyledButton>
                        <StyledButton type="button" onClick={handlePrint}>
                          Print
                        </StyledButton>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </StyledBgDivOne>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={handleCloseSnackbar}
                severity="success"
              >
                Submitted successfully!
              </Alert>
            </Snackbar>
          </Col>): <></> }
          
        </Row>
      </form>
    </div>
  );
}
