import styled from "styled-components";

export const StyledService = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 1300px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 1200px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 1100px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: auto;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: auto;
  }
  @media only screen and (min-width: 401px) and (max-width: 640px) {
    height: auto;
  }
  @media only screen and (min-width: 320px) and (max-width: 400px) {
    height: auto;
  }
`;
export const StyledServiceHead = styled.div`
  background-image: url("../bg1.png");
  background-repeat: no-repeat;
  background-position: left 140px;
`;
export const StyledHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5%;
`;
export const TitleHeading = styled.h2`
  font-family: "Montserrat";
  font-size: 32px;
  line-height: 39.01px;
  text-align: center;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
  width: 40%;
  span {
    color: #df870c;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 32px;
    line-height: 39.01px;
    width: 45%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 28px;
    line-height: 34.01px;
    width: 45%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
    line-height: 29.01px;
    width: 45%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
    line-height: 24.38px;
    width: 45%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
    line-height: 17.07px;
    width: 53%;
  }
`;
export const ServicesMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 80px;
  margin-top: 3%;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    gap: 26px;
  }
`;
export const ServicesLogo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height:102px;
`;
export const ResponsiveImage1 = styled.img`
  width: 101.5px;
  height: 115.77px;
  transform: translateY(18px);
  position:relative;
  left:28%;
  @media only screen and (min-width:1281px) and (max-width:1536px){
	width:90px;
	height:102px
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
	width:76px;
	height:87px
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
	width:57px;
	height:65px
  }
  @media only screen and (min-width:641px) and (max-width:810px){
	width:45px;
	height:52px
  }
  @media only screen and (min-width:320px) and (max-width:640px){
	width:36px;
	height:41px
  }
`;
export const ResponsiveImage2 = styled.img`
  width: 144.5px;
  height: 96px;
  transform: translateY(18px);
  position:relative;
  left:24%;
  margin-top: 20%;
  @media only screen and (min-width:1281px) and (max-width:1536px){
	width:127px;
	height:85px
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
	width:108px;
	height:72px
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
	width:81px;
	height:54px
  }
  @media only screen and (min-width:641px) and (max-width:810px){
	width:64px;
	height:43px
  }
  @media only screen and (min-width:320px) and (max-width:640px){
	width:51px;
	height:34px
  }
`;
export const ResponsiveImage3 = styled.img`
  width: 100px;
  height: 134px;
  transform: translateY(18px);
  position:relative;
  left:24%;
 
  @media only screen and (min-width:1281px) and (max-width:1536px){
	width:88px;
	height:119px
  }
  @media only screen and (min-width:1081px) and (max-width:1280px){
	width:75px;
	height:101px
  }
  @media only screen and (min-width:811px) and (max-width:1080px){
	width:56px;
	height:75px
  }
  @media only screen and (min-width:641px) and (max-width:810px){
	width:45px;
	height:60px
  }
  @media only screen and (min-width:320px) and (max-width:640px){
	width:35px;
	height:47px
  }
`;
export const StyledOuter = styled.div`
  width: 110.33px;
  position:absolute;
  z-index:-1;
  height: 110px;
  border-radius: 72px;
  background: linear-gradient(180deg, #f3cf90 0%, rgba(217, 217, 217, 0) 100%);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 97.86px;
    height: 97.86px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 82.96px;
    height: 82.96px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 62.4px;
    height: 62.4px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 49.67px;
    height: 49.67px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 39.36px;
    height: 39.36px;
  }
`;
export const StyledIconText = styled.div`
  margin-left: 14%;
  width: 50%;
  margin-top:2%;
`;

export const StyledText = styled.p`
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  line-height: 24.3px;
  text-align: center;
  color: #000000;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 20px;
    line-height: 24.3px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 18px;
    line-height: 21.3px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
    line-height: 19.3px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
    line-height: 17.3px;
  }
  @media only screen and (min-width: 320px) and (max-width: 641px) {
    font-size: 12px;
    line-height: 14.3px;
  }
`;
export const ParaServiceHeading = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  text-align: center;
  width: 82%;
  margin-top: 3%;
  span {
    font-weight: 600;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 20px;
    line-height: 41px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 18px;
    line-height: 37px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
    line-height: 27px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 15px;
    line-height: 24px;
  }
  @media only screen and (min-width: 320px) and (max-width: 641px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
export const ServiceHeadGap = styled.div`
  padding: 40px;
`;
export const StyledImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Image = styled.img`
  width: 932px;
  height: 537px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 827px;
    height: 476px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 716px;
    height: 412px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 523px;
    height: 301px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 470px;
    height: 269px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 256px;
    height: 146px;
  }
`;
