import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  height: 830px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 730px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 630px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 530px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 430px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 290px;
  }
`;
export const StyledText = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  //   height: 800px;
  // }
  // @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  //   height: 700px;
  // }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 40%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 30%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 35%;
  }
`;
export const StyledP = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(223, 135, 12, 1);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 11px;
    margin-bottom: 0;
  }
`;
export const StyledH2 = styled.h2`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(7, 6, 6, 1);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 28px;
    line-height: 34.12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
    line-height: 28.32px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
    line-height: 23.52px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
    line-height: 16.52px;
  }
`;
export const StyledCardContainer = styled.div`
  height: 850px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("bg1.png"), 50%;
  background-repeat: no-repeat;
  background-position: right;
  // background-size: cover;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 551px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 467px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 351px;
    background-size: 500px 400px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 280px;
    background-size: 400px 300px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 139px;
    background-size: 200px 150px;
  }
`;
export const StyledContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2%;
`;
export const StyledCard = styled.div`
  width: 259.51px;
  height: 131.81px;
  display: flex;
  justify-content: center;
  align-items: center;
  // visibility: visible !impotant;
  // background-repeat: no-repeat;
  // background-position: center;
  box-shadow: 0px 4px 19.399999618530273px 0px rgba(0, 0, 0, 0.15);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 230.17px;
    height: 116.91px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 195.17px;
    height: 99.91px;
    background-size: 80% 80%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 146.77px;
    height: 74.55px;
    background-size: 80% 80%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 116.83px;
    height: 59.34px;
    background-size: 80% 80%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 58.32px;
    height: 29.62px;
    background-size: 80% 80%;
  }
`;
export const StyledImg1 = styled.img`
  width: 202.55px;
  height: 82.45px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 179.65px;
    height: 73.13px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 152.3px;
    height: 61.99px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 114.55px;
    height: 46.63px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 91.19px;
    height: 37.12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 45.52px;
    height: 18.53px;
  }
`;
export const StyledImg2 = styled.img`
  width: 194.89px;
  height: 44.37px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 172.86px;
    height: 39.35px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 146.54px;
    height: 33.36px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 110.22px;
    height: 25.09px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 87.74px;
    height: 19.98px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 43.79px;
    height: 9.97px;
  }
`;
export const StyledImg3 = styled.img`
  width: 96.75px;
  height: 97.25px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 85.81px;
    height: 86.25px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 72.74px;
    height: 73.12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 54.72px;
    height: 55px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 43.56px;
    height: 43.78px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 21.74px;
    height: 21.85px;
  }
`;
export const StyledImg4 = styled.img`
  width: 132.14px;
  height: 98.99px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 117.2px;
    height: 87.8px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 99.36px;
    height: 74.43px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 74.73px;
    height: 55.98px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 59.49px;
    height: 44.57px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 29.69px;
    height: 22.24px;
  }
`;
export const StyledImg5 = styled.img`
  width: 113.96px;
  height: 113.96px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 101.08px;
    height: 101.08px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 85.69px;
    height: 85.69px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 64.45px;
    height: 64.45px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 51.31px;
    height: 51.31px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 25.61px;
    height: 25.61px;
  }
`;
export const StyledImg6 = styled.img`
  width: 207.33px;
  height: 44.32px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 183.9px;
    height: 39.3px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 155.9px;
    height: 33.31px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 117.26px;
    height: 25.04px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 93.34px;
    height: 19.93px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 46.59px;
    height: 9.94px;
  }
`;
export const StyledImg7 = styled.img`
  width: 160.65px;
  height: 108.47px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 142.49px;
    height: 96.21px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 120.79px;
    height: 81.56px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 90.85px;
    height: 61.35px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 72.32px;
    height: 48.83px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 36.1px;
    height: 24.38px;
  }
`;
export const StyledImg8 = styled.img`
  width: 105.73px;
  height: 101.61px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 93.77px;
    height: 90.12px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 79.5px;
    height: 76.4px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 59.79px;
    height: 57.46px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 47.6px;
    height: 45.74px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 23.76px;
    height: 22.83px;
  }
`;
export const StyledImg9 = styled.img`
  width: 215.8px;
  height: 71.67px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 191.4px;
    height: 63.56px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 162.26px;
    height: 53.89px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 122.04px;
    height: 40.53px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 97.15px;
    height: 32.26px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 48.49px;
    height: 16.1px;
  }
`;
export const StyledImg10 = styled.img`
  width: 215.8px;
  height: 71.67px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 177.35px;
    height: 50.81px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 177.35px;
    height: 50.81px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 133.39px;
    height: 38.22px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 106.19px;
    height: 30.44px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 53px;
    height: 15.2px;
  }
`;
export const StyledImg11 = styled.img`
  width: 154.06px;
  height: 54.56px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 136.64px;
    height: 48.39px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 115.84px;
    height: 41.03px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 87.13px;
    height: 30.86px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 69.36px;
    height: 24.56px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 34.62px;
    height: 12.26px;
  }
`;
export const StyledImg12 = styled.img`
  width: 207.64px;
  height: 110.03px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 184.17px;
    height: 97.59px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 156.12px;
    height: 82.73px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 117.43px;
    height: 62.23px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 93.48px;
    height: 49.54px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 46.66px;
    height: 24.73px;
  }
`;
export const StyledImg13 = styled.img`
  width: 229.27px;
  height: 58.56px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 203.35px;
    height: 51.94px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 172.39px;
    height: 44.03px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 129.66px;
    height: 33.12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 103.22px;
    height: 26.36px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 51.52px;
    height: 13.16px;
  }
`;
export const StyledImg14 = styled.img`
  width: 146.43px;
  height: 92.7px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 129.88px;
    height: 82.21px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 110.1px;
    height: 69.71px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 82.81px;
    height: 52.43px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 65.92px;
    height: 41.74px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 32.9px;
    height: 20.84px;
  }
`;
export const StyledImg15 = styled.img`
  width: 146.43px;
  height: 92.7px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 199.55px;
    height: 85.13px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 169.17px;
    height: 72.17px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 127.24px;
    height: 54.28px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 101.29px;
    height: 43.21px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 50.56px;
    height: 21.57px;
  }
`;
export const StyledImg16 = styled.img`
  width: 237.54px;
  height: 70.03px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 210.69px;
    height: 62.11px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 134.34px;
    height: 39.6px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 134.34px;
    height: 39.6px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 106.94px;
    height: 31.53px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 53.38px;
    height: 15.74px;
  }
`;
